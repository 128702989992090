<!--header-->
<div class="md:_x_relative">
  <!-- sticky search input for mobile -->
  <div
    *ngIf="!isVisible"
    [formGroup]="searchForm"
    class="_x_fixed _x_top-0 _x_z-10 _x_flex _x_w-full _x_bg-white _x_p-8 _x_shadow-headerShadow md:_x_hidden">
    <div class="_x_relative _x_flex _x_w-full _x_items-center _x_overflow-hidden md:_x_hidden">
      <input
        #inputMobile
        (focus)="onfocus()"
        (focusout)="focusOutMobile()"
        (keyup.enter)="search(searchForm.get('searchKeyword').value, undefined, $event)"
        [ngClass]="{
          '_x_pl-40': (selectUploadedImageForSearch | async),
          '_x_pl-8': !(selectUploadedImageForSearch | async),
          '_x_pr-[46px]': getSearchInputValue,
          '_x_pl-60': showExpressSearch
        }"
        [placeholder]="showExpressSearch ? 'მოძებნე ექსპრესში' : 'მოძებნე რაც გაგიხარდება'"
        class="_x_h-full _x_w-full _x_overflow-ellipsis _x_rounded-l-8 _x_rounded-r-none _x_border-2 _x_border-r-0 _x_border-purple _x_pr-18 _x_font-regular _x_text-3 _x_text-dark-700 _x_outline-none"
        id="inputMobile"
        name="search"
        formControlName="searchKeyword"
        type="text"
        aria-label="Search input" />
      <div
        *ngIf="selectUploadedImageForSearch | async"
        class="_x_absolute _x_bottom-0 _x_left-0 _x_top-0 _x_m-2 _x_ml-3 _x_flex _x_h-19 _x_max-h-full _x_items-center _x_rounded-4 _x_bg-dark-100 _x_p-2">
        <img
          [alt]="(selectUploadedImageForSearch | async).externalId"
          [src]="(selectUploadedImageForSearch | async).externalUrl"
          class="_x_mr-2 _x_max-h-15 _x_w-15 _x_rounded-8" />
        <i
          (click)="clearUploadedSearchImage()"
          class="_x_icon _x_icon-remove-bin _x_flex _x_h-12 _x_max-h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_cursor-pointer _x_items-center _x_justify-center _x_text-6 _x_text-dark-500"
          aria-label="Clear uploaded image"></i>
      </div>
      <div class="_x_absolute _x_right-[64px]">
        <i
          (click)="clearSearch()"
          *ngIf="searchForm.get('searchKeyword').value"
          class="_x_icon _x_icon-remove _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_cursor-pointer _x_items-center _x_justify-center _x_text-6 _x_text-dark-500"
          aria-label="Clear search input"></i>
      </div>
      <!--      <button-->
      <!--        (click)="!showExpressSearch && setImageSearchModalState(true)"-->
      <!--        [disabled]="showExpressSearch"-->
      <!--        aria-label="Open image search modal"-->
      <!--        class="_x_absolute _x_right-20 _x_z-1">-->
      <!--        <i-->
      <!--          class="_x_icon _x_icon-camera _x_mr-10 _x_flex _x_h-12 _x_max-h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_cursor-pointer _x_items-center _x_text-7 _x_text-dark-700"></i>-->
      <!--      </button>-->
      <button
        (click)="search(userTypedValue, undefined, $event)"
        aria-label="Search"
        class="_x_z-2 _x_flex _x_h-24 _x_items-center _x_justify-center _x_rounded-r-8 _x_border-2 _x_border-purple _x_bg-purple _x_px-6 _x_font-medium _x_text-white _x_transition _x_duration-300 hover:_x_bg-purple-100 hover:_x_text-purple">
        <i
          class="_x_icon _x_icon-search _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_justify-center _x_text-7"></i>
      </button>
    </div>

    <!-- fixed search suggestions mobile  -->
    <div
      *ngIf="showSuggestions"
      class="_x_shadow _x_absolute _x_left-50prc _x_top-34 _x_z-10 _x_flex _x_w-calc-w-full-32 _x_translate-x-[-50%] _x_flex-col _x_gap-4 _x_rounded-12 _x_border _x_border-dark-100 _x_bg-white _x_p-8"
      role="listbox"
      aria-labelledby="inputMobile">
      <a
        (click)="search(userTypedValue, elem, $event)"
        *ngFor="let elem of suggestions"
        class="_x_flex _x_w-full _x_gap-4 _x_rounded-4 _x_font-medium _x_text-3 _x_text-dark"
        role="option">
        <i class="_x_icon _x_icon-search _x_text-4 _x_text-dark-500"></i>
        <span class="_x_mt-[-2px]">
          {{ getFirstLettersOfUserInput(elem) }}<span class="_x_font-bold">{{ getLastLettersOfUserInput(elem) }}</span>
        </span>
      </a>
    </div>
    <!-- fixed search suggestions mobile -->

    <!--  fixed search history mobile -->
    <!--    <div-->
    <!--      *ngIf="showSuggestions"-->
    <!--      role="listbox"-->
    <!--      aria-labelledby="historyMobile"-->
    <!--      class="_x_shadow _x_absolute _x_left-50prc _x_top-34 _x_z-10 _x_flex _x_w-calc-w-full-32 _x_translate-x-[-50%] _x_flex-col _x_gap-4 _x_rounded-12 _x_border _x_border-dark-100 _x_bg-white _x_p-8">-->
    <!--      <div class="_x_mb-2 _x_flex _x_items-center _x_justify-between">-->
    <!--        <h4 class="_x_font-bold _x_text-3 _x_text-dark">ძიების ისტორია</h4>-->
    <!--        <button class="_x_font-medium _x_text-2 _x_text-link-blue">გასუფთავება</button>-->
    <!--      </div>-->
    <!--      <div role="option" *ngFor="let elem of suggestions" class="_x_flex _x_items-start _x_justify-between _x_gap-10">-->
    <!--        <a-->
    <!--          (click)="search(userTypedValue, elem, $event)"-->
    <!--          class="_x_flex _x_w-full _x_gap-4 _x_rounded-4 _x_font-medium _x_text-3 _x_text-dark"-->
    <!--          role="option">-->
    <!--          <i class="_x_icon _x_icon-history _x_text-4 _x_text-dark-500"></i>-->
    <!--          <span class="_x_mt-[-2px]">-->
    <!--            {{ getFirstLettersOfUserInput(elem)-->
    <!--            }}<span class="_x_font-bold">{{ getLastLettersOfUserInput(elem) }}</span>-->
    <!--          </span>-->
    <!--        </a>-->

    <!--        <button class="_x_mt-[-2px]">-->
    <!--          <i class="_x_icon-remove _x_text-3 _x_text-dark-700"></i>-->
    <!--        </button>-->
    <!--      </div>-->
    <!--    </div>-->
    <!-- fixed search history mobile  -->
  </div>

  <!---->

  <header class="_x_relative _x_left-0 _x_z-[50] _x_w-full _x_bg-white">
    <div class="_x_top-0 _x_h-0 _x_w-full md:_x_fixed">
      <div>
        <app-smart-banner *ngIf="smartBannerService.openSmartBanner$ | async"></app-smart-banner>
      </div>
      <ng-container *ngIf="selectRibbonActiveState$ | async; else beforeRibbonLoads">
        <div
          class="_x_relative _x_flex _x_h-24 _x_w-full _x_items-center _x_justify-center _x_bg-purple _x_px-8 _x_py-6 _x_text-center md:_x_h-18"
          role="banner">
          <img
            *ngIf="(selectRibbon$ | async).url"
            [alt]="(selectRibbon$ | async).iconDescription || 'Banner icon'"
            class="_x_align-center _x_relative _x_mr-5 _x_flex _x_h-12 _x_max-h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_justify-center"
            [src]="(selectRibbon$ | async).url" />
          <p
            class="_x_line-clamp-2 _x_font-semibold _x_text-1 _x_text-white md:_x_line-clamp-1 md:_x_font-bold md:_x_text-3">
            {{ (selectRibbon$ | async).bannerDescription }}
          </p>
        </div>
      </ng-container>
      <ng-template #beforeRibbonLoads>
        <div class="_x_min-h-24 sm:_x_min-h-18" aria-hidden="true"></div>
      </ng-template>

      <!-- top head -->
      <div [ngClass]="showCategory ? '_x_shadow-none' : '_x_shadow-headerShadow'">
        <div
          class="_x_flex _x_cursor-pointer _x_items-center _x_border-b-1 _x_border-dark-100 _x_bg-white sm:_x_hidden">
          <div
            (click)="openAddressFormModal()"
            class="_x_z-50 _x_flex _x_w-full _x_items-center _x_bg-white _x_px-8 _x_py-4">
            <i
              class="_x_icon-location _x_mr-4 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_text-8 _x_text-black group-hover:_x_text-black"></i>
            <p
              class="_x_overflow-hidden _x_overflow-ellipsis _x_whitespace-nowrap _x_font-medium _x_text-3 _x_text-dark-400">
              {{ selectedAddress?.address || 'მისამართის დამატება' }}
            </p>
          </div>
          <div
            *ngIf="expressService.showAddressSuggestionPopup"
            [ngClass]="!(smartBannerService.openSmartBanner$ | async) || isFromSafari ? '_x_top-0' : '_x_top-[77px]'"
            class="left-0 _x_fixed _x_z-10 _x_h-screen _x_min-h-full _x_w-screen _x_min-w-full _x_overflow-visible _x_bg-dark-900 sm:_x_hidden sm:_x_px-8">
            <app-popular-banner
              (closeExpressAddressSuggestion)="hideExpressPopup()"
              class="_x_m-auto _x_mt-50 _x_flex _x_w-full _x_max-w-780 _x_justify-center _x_pl-0 _x_pr-8 sm:_x_mt-104 sm:_x_justify-between sm:_x_px-0 md:_x_mt-83"></app-popular-banner>
          </div>
        </div>

        <div
          [ngClass]="{ 'md:_x_border-t-0 md:_x_border-dark-100': showHr }"
          class="_x_flex _x_w-full _x_items-center _x_bg-white md:_x_h-34">
          <div class="_x_m-auto _x_w-full _x_max-w-780 _x_px-8 _x_pb-0">
            <div class="_x_flex _x_justify-between _x_gap-5 _x_pb-7 _x_pt-7 md:_x_p-0">
              <div class="_x_mr-3 _x_flex _x_items-center md:_x_gap-10">
                <!-- mobile burger btn -->
                <button
                  (click)="openCategories()"
                  aria-label="Open Categories"
                  class="_x_mr-10 _x_flex _x_items-center _x_justify-center _x_bg-purple _x_bg-transparent _x_text-dark _x_outline-none hover:_x_text-purple md:_x_hidden">
                  <i
                    [ngClass]="showCategory ? '_x_icon-remove' : '_x_icon-burger'"
                    class="_x_icon _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_justify-center _x_text-7"></i>
                </button>
                <!-- mobile burger btn /.-->

                <a
                  (click)="scrollTop()"
                  class="_x_w-54 _x_outline-none sm:_x_mr-6 md:_x_mr-0 md:_x_min-w-74"
                  routerLink="/"
                  aria-label="go to home page">
                  <img
                    [src]="'../assets/atomic-assets/img/logo/new-logo-black-no-whitespace.svg'"
                    alt="extra.ge logo"
                    class="_x_h-[48px] _x_w-full" />
                </a>
                <!-- logo/. -->

                <!-- address input -->
                <div
                  [ngClass]="{
                    '_x_opacity-1': !disableAddressInput || !hasUserPrivacyPolicyUpdated,
                    '_x_cursor-pointer': disableAddressInput && hasUserPrivacyPolicyUpdated
                  }"
                  class="_x_relative _x_hidden _x_w-100 _x_items-center _x_px-2 sm:_x_flex md:_x_h-24">
                  <div
                    (click)="openAddressFormModal()"
                    [ngClass]="{ '_x_ml-1 _x_border-2': expressService.showAddressSuggestionPopup }"
                    class="_x_z-11 _x_flex _x_w-96 _x_items-center _x_rounded-6 _x_border-purple _x_bg-white">
                    <i
                      class="_x_icon-location _x_mr-3 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_text-8 _x_text-black group-hover:_x_text-black"></i>

                    <p
                      *ngIf="isMobile; else webAddressInput"
                      [ngClass]="selectedAddress?.address ? '_x_text-dark' : '_x_text-dark-400'"
                      class="xl:215 _x_w-full _x_max-w-120 _x_overflow-hidden _x_overflow-ellipsis _x_whitespace-nowrap _x_font-medium _x_text-3 lg:_x_max-w-189">
                      {{ selectedAddress?.address || 'მისამართის დამატება' }}
                    </p>

                    <ng-template #webAddressInput>
                      <div class="_x_flex _x_w-84 _x_flex-col _x_justify-center _x_gap-1">
                        <p *ngIf="selectedAddress?.address" class="_x_font-bold _x_text-3 _x_text-dark">
                          {{ selectedAddress?.location }}
                        </p>
                        <p
                          [ngClass]="
                            selectedAddress?.address ? '_x_text-2 _x_text-dark-700' : '_x_text-3 _x_text-dark-500'
                          "
                          class="xl:215 _x_w-full _x_max-w-120 _x_overflow-hidden _x_overflow-ellipsis _x_whitespace-nowrap _x_font-medium lg:_x_max-w-83">
                          {{
                            (this.selectedAddress?.address | splitAddress: this.selectedAddress?.location) ||
                              'მისამართის დამატება'
                          }}
                        </p>
                      </div>
                    </ng-template>
                  </div>

                  <div
                    *ngIf="expressService.showAddressSuggestionPopup"
                    class="_x_fixed _x_left-0 _x_top-0 _x_z-10 _x_h-screen _x_min-h-full _x_w-screen _x_min-w-full _x_bg-dark-900"></div>
                  <app-popular-banner
                    *ngIf="expressService.showAddressSuggestionPopup"
                    (closeExpressAddressSuggestion)="hideExpressPopup()"
                    class="_x_absolute _x_top-[38px] _x_z-50 _x_m-auto _x_flex _x_w-max _x_max-w-780 _x_justify-between md:_x_top-[48px]"></app-popular-banner>
                </div>
              </div>

              <!-- search -->
              <div [formGroup]="searchForm" class="_x_relative _x_hidden _x_w-362 md:_x_flex">
                <div class="_x_relative _x_hidden _x_w-full _x_items-center _x_overflow-hidden md:_x_flex">
                  <input
                    #inputDesktop
                    (focus)="onfocus()"
                    (focusout)="focusOut()"
                    (keyup)="updateSuggestionsVariable($event)"
                    (keyup.arrowdown)="markSuggestion($event)"
                    (keyup.arrowup)="markSuggestion($event)"
                    (keyup.enter)="search(searchForm.get('searchKeyword')?.value, undefined, $event)"
                    [ngClass]="{
                      '_x_pl-40': (selectUploadedImageForSearch | async),
                      '_x_pl-8': !(selectUploadedImageForSearch | async),
                      '_x_pr-[46px]': getSearchInputValue,
                      'md:_x_pl-67': showExpressSearch
                    }"
                    [placeholder]="searchPlaceholder"
                    class="_x_h-full _x_w-full _x_overflow-ellipsis _x_rounded-l-8 _x_rounded-r-none _x_border-2 _x_border-r-0 _x_border-purple _x_pr-18 _x_font-regular _x_text-3 _x_text-dark-700 _x_outline-none"
                    formControlName="searchKeyword"
                    id="inputDesktop"
                    name="search"
                    type="text" />
                  <div
                    *ngIf="selectUploadedImageForSearch | async"
                    class="_x_absolute _x_left-0 _x_m-3 _x_flex _x_h-19 _x_items-center _x_rounded-4 _x_bg-dark-100 _x_p-2">
                    <img
                      [alt]="(selectUploadedImageForSearch | async).externalId"
                      [src]="(selectUploadedImageForSearch | async).externalUrl"
                      class="_x_mr-2 _x_max-h-15 _x_w-15 _x_rounded-8" />
                    <i
                      (click)="clearUploadedSearchImage()"
                      class="_x_icon _x_icon-remove-bin _x_flex _x_h-12 _x_max-h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_cursor-pointer _x_items-center _x_justify-center _x_text-6 _x_text-dark-500"></i>
                  </div>
                  <!-- search cases -->
                  <div
                    *ngIf="showExpressSearch"
                    class="_x_absolute _x_left-0 _x_m-2 _x_flex _x_h-20 _x_items-center _x_rounded-4 _x_bg-dark-100 _x_px-6 _x_py-5">
                    <span class="_x_mr-4 _x_font-medium _x_text-3 _x_text-black">ექსპრესი</span>
                    <i
                      (click)="removeExpressSearch()"
                      class="_x_icon _x_icon-remove _x_flex _x_h-8 _x_max-h-8 _x_min-h-8 _x_w-8 _x_min-w-8 _x_cursor-pointer _x_items-center _x_justify-center _x_text-4 _x_text-black"></i>
                  </div>
                  <!-- search cases -->
                  <div class="_x_absolute _x_right-[64px]">
                    <i
                      (click)="clearSearch()"
                      *ngIf="searchForm.get('searchKeyword').value"
                      class="_x_icon _x_icon-remove _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_cursor-pointer _x_items-center _x_justify-center _x_text-6 _x_text-dark-500"></i>
                  </div>
                  <!--                  <button-->
                  <!--                    (click)="!showExpressSearch && setImageSearchModalState(true)"-->
                  <!--                    [disabled]="showExpressSearch"-->
                  <!--                    aria-label="Justify"-->
                  <!--                    class="_x_absolute _x_right-20 _x_z-1">-->
                  <!--                    <i-->
                  <!--                      class="_x_icon _x_icon-camera _x_mr-12 _x_flex _x_h-12 _x_max-h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_cursor-pointer _x_items-center _x_text-7 _x_text-dark-700"></i>-->
                  <!--                  </button>-->
                  <button
                    (click)="search(userTypedValue, undefined, $event)"
                    aria-label="Justify"
                    class="_x_z-2 _x_flex _x_h-24 _x_items-center _x_justify-center _x_rounded-r-8 _x_border-2 _x_border-purple _x_bg-purple _x_px-6 _x_font-medium _x_text-white _x_transition _x_duration-300 hover:_x_bg-purple-100 hover:_x_text-purple">
                    <i
                      class="_x_icon _x_icon-search _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_justify-center _x_text-7"></i>
                  </button>
                </div>

                <!-- search suggestions desktop  -->
                <div
                  *ngIf="showSuggestions"
                  class="_x_shadow _x_absolute _x_left-0 _x_top-27 _x_z-12 _x_hidden _x_w-full _x_flex-col _x_rounded-12 _x_border _x_border-dark-100 _x_bg-white _x_p-6 md:_x_flex"
                  role="listbox"
                  aria-labelledby="inputDesktop">
                  <a
                    (click)="search(userTypedValue, elem, $event)"
                    *ngFor="let elem of suggestions; let i = index"
                    [ngClass]="i === suggestionsKeyControlIndex ? '_x_bg-dark-100' : ''"
                    class="_x_flex _x_cursor-pointer _x_gap-4 _x_rounded-4 _x_px-2 _x_pb-[3px] _x_pt-[7px] _x_font-medium _x_text-3 _x_text-dark _x_transition _x_duration-300 hover:_x_bg-dark-100"
                    role="option">
                    <i class="_x_icon _x_icon-search _x_text-4 _x_text-dark-500"></i>
                    <span class="_x_mt-[-2px]">
                      {{ getFirstLettersOfUserInput(elem)
                      }}<span class="_x_font-bold">{{ getLastLettersOfUserInput(elem) }}</span>
                    </span>
                  </a>
                </div>
                <!-- search suggestions desktop /.-->

                <!--  search history desktop  -->
                <!--                <div-->
                <!--                  *ngIf="showSuggestions"-->
                <!--                  role="listbox"-->
                <!--                  aria-labelledby="historyMobile"-->
                <!--                  class="_x_shadow _x_absolute _x_left-0 _x_top-26 _x_z-10 _x_flex _x_w-full _x_flex-col _x_rounded-12 _x_border _x_border-dark-100 _x_bg-white _x_p-6">-->
                <!--                  <div class="_x_mb-3 _x_flex _x_items-center _x_justify-between _x_px-2">-->
                <!--                    <h4 class="_x_font-bold _x_text-3 _x_text-dark">ძიების ისტორია</h4>-->
                <!--                    <button class="_x_font-medium _x_text-2 _x_text-link-blue">გასუფთავება</button>-->
                <!--                  </div>-->
                <!--                  <div-->
                <!--                    *ngFor="let elem of suggestions"-->
                <!--                    class="_x_flex _x_items-start _x_justify-between _x_gap-10 _x_rounded-4 _x_px-2 _x_pb-[1px] _x_pt-[5px] _x_transition _x_duration-300 hover:_x_bg-dark-100">-->
                <!--                    <a-->
                <!--                      (click)="search(userTypedValue, elem, $event)"-->
                <!--                      class="_x_flex _x_w-full _x_cursor-pointer _x_gap-4 _x_font-medium _x_text-3 _x_text-dark"-->
                <!--                      role="option">-->
                <!--                      <i class="_x_icon _x_icon-history _x_text-4 _x_text-dark-500"></i>-->
                <!--                      <span class="_x_mt-[-2px]">-->
                <!--                        {{ getFirstLettersOfUserInput(elem)-->
                <!--                        }}<span class="_x_font-bold">{{ getLastLettersOfUserInput(elem) }}</span>-->
                <!--                      </span>-->
                <!--                    </a>-->

                <!--                    <button class="_x_mt-[-2px]">-->
                <!--                      <i class="_x_icon-remove _x_text-4 _x_text-dark-700"></i>-->
                <!--                    </button>-->
                <!--                  </div>-->
                <!--                </div>-->
                <!-- search history desktop  -->
              </div>
              <!-- search /.-->

              <!--  right bar -->
              <div
                class="_x_relative _x_flex _x_w-full _x_items-center _x_justify-between xs:_x_w-auto lg:_x_pl-10 xl:_x_pl-15">
                <a
                  aria-label="visit comparison page"
                  class="_x_flex _x_flex-col _x_items-center _x_justify-end _x_text-dark hover:_x_text-purple"
                  routerLink="/comparison">
                  <i
                    class="_x_icon _x_icon-Compare _x_relative _x_flex _x_h-12 _x_max-h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_justify-center _x_text-7">
                    <div
                      *ngIf="getComparisonCount$ | async"
                      class="_x_absolute _x_left-8 _x_top-minus-5 _x_rounded-10 _x_bg-white _x_p-1">
                      <div class="_x_rounded-10 _x_bg-red _x_px-2 _x_py-1">
                        <p
                          class="_x_flex _x_h-7 _x_min-w-4 _x_items-center _x_justify-center _x_font-bold _x_text-2 _x_text-white">
                          {{ getComparisonCount$ | async }}
                        </p>
                      </div>
                    </div>
                  </i>

                  <span class="_x_text _x_font-light _x_hidden _x_font-medium _x_text-2 _x_text-dark-700 md:_x_flex"
                    >შედარება</span
                  >
                </a>
                <a
                  aria-label="visit wishlist page"
                  class="wishlist-icon _x_flex _x_flex-col _x_items-center _x_justify-end _x_text-dark hover:_x_text-purple xs:_x_mx-12 md:_x_mx-16"
                  routerLink="/wishlist">
                  <div
                    class="_x_icon _x_relative _x_flex _x_h-12 _x_max-h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_justify-center _x_text-7">
                    <i class="_x_icon-Favorites-new-outline"></i>
                    <div
                      *ngIf="getWishlistProductsCount$ | async"
                      class="_x_absolute _x_left-5 _x_top-minus-5 _x_rounded-10 _x_bg-white _x_p-1">
                      <div class="_x_rounded-10 _x_bg-red _x_px-2 _x_py-1">
                        <p
                          class="_x_flex _x_h-7 _x_min-w-4 _x_items-center _x_justify-center _x_font-bold _x_text-2 _x_text-white">
                          {{ getWishlistProductsCount$ | async }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <span class="_x_text _x_font-light _x_hidden _x_font-medium _x_text-2 _x_text-dark-700 md:_x_flex"
                    >რჩეულები</span
                  >
                </a>
                <a
                  aria-label="visit basket page"
                  class="_x_flex _x_flex-col _x_items-center _x_justify-end _x_text-dark hover:_x_text-purple xs:_x_mr-12 md:_x_mr-16"
                  routerLink="/basket">
                  <i
                    class="_x_icon _x_icon-cart-bucket _x_relative _x_flex _x_h-12 _x_max-h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_justify-center _x_text-7">
                    <div
                      *ngIf="getBasketProductCount$ | async"
                      class="_x_absolute _x_left-5 _x_top-minus-5 _x_rounded-10 _x_bg-white _x_p-1">
                      <div class="_x_rounded-10 _x_bg-red _x_px-2 _x_py-1">
                        <p
                          class="_x_flex _x_h-7 _x_min-w-4 _x_items-center _x_justify-center _x_font-bold _x_text-2 _x_text-white">
                          {{ getBasketProductCount$ | async }}
                        </p>
                      </div>
                    </div>
                  </i>

                  <span class="_x_text _x_font-light _x_hidden _x_font-medium _x_text-2 _x_text-dark-700 md:_x_flex"
                    >კალათა</span
                  >
                </a>
                <button
                  (click)="toSignIn()"
                  *ngIf="!(userStatus$ | async)"
                  aria-label="login"
                  class="_x_flex _x_h-15 _x_w-auto _x_items-center _x_justify-center _x_rounded-8 _x_font-medium _x_text-dark _x_transition _x_duration-300 hover:_x_border-dark md:_x_mr-0 md:_x_h-24 md:_x_w-57 md:_x_border md:_x_border-dark-200 md:_x_px-8">
                  <span class="_x_mr-1 _x_hidden _x_font-bold _x_text-3 md:_x_block">შესვლა</span>
                  <i
                    class="_x_icon _x_icon-user-profile-1 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_justify-center _x_text-7"></i>
                </button>

                <!-- mobile dropdown -->
                <div
                  *ngIf="(userStatus$ | async) && (user$ | async) as user"
                  appDropDown
                  class="_x_flex _x_h-full _x_items-center _x_justify-center md:_x_hidden">
                  <div class="_x_flex _x_cursor-pointer _x_items-center _x_outline-none">
                    <span class="dropper">
                      <figure
                        *ngIf="user.avatarUrl; else defIcon"
                        class="_x_mb-0 _x_ml-5 _x_flex _x_h-12 _x_w-12 _x_min-w-12 _x_items-center _x_justify-center _x_overflow-hidden _x_rounded-full xs:_x_ml-0 md:_x_h-24 md:_x_w-24 md:_x_min-w-24">
                        <img [src]="user.avatarUrl" alt="avatar icon" class="_x_max-w-full _x_max-h-full _x_w-full" />
                      </figure>
                      <ng-template #defIcon>
                        <i
                          class="_x_icon _x_icon-user-profile-1 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_justify-center _x_rounded-full _x_bg-dark-100 _x_text-7 _x_text-white"></i>
                      </ng-template>
                    </span>
                    <!-- mobile user info and links list -->
                    <div
                      class="drop _x_fixed _x_left-0 _x_top-0 _x_z-51 _x_h-screen _x_w-full _x_overflow-y-auto _x_bg-white _x_px-10 _x_pb-10 _x_pt-4">
                      <div class="_x_flex _x_justify-end">
                        <button
                          class="_x_icon _x_icon-remove _x_flex _x_h-10 _x_max-h-10 _x_min-h-10 _x_w-10 _x_min-w-10 _x_cursor-pointer _x_items-center _x_justify-center _x_p-10 _x_text-4 _x_text-black"></button>
                      </div>
                      <div class="_x_flex _x_flex-col _x_items-center">
                        <!--User Profile photo-->
                        <figure
                          class="_x_ml-none _x_mr-none _x_mt-none _x_mb-8 _x_flex _x_h-40 _x_min-h-40 _x_w-40 _x_min-w-40 _x_items-center _x_justify-center _x_overflow-hidden _x_rounded-full _x_border-2 _x_border-white _x_bg-dark-100 _x_shadow-profileAvatarShadowOnMobile">
                          <label class="_x_m-0" for="imageUpload">
                            <img
                              *ngIf="user.avatarUrl; else noAvatar"
                              [src]="user.avatarUrl"
                              alt="avatar icon"
                              class="_x_max-w-full _x_w-full _x_cursor-pointer" />
                            <ng-template #noAvatar>
                              <i
                                class="_x_items-center_x_text-white _x_icon-user-profile-1 _x_flex _x_text-icon-16"></i>
                            </ng-template>
                          </label>
                        </figure>
                        <!-- auth mobile dropdown list -->
                        <ng-template #defIcon>
                          <i
                            class="_x_icon _x_icon-user-profile-1 _x_flex _x_h-24 _x_min-h-24 _x_w-24 _x_min-w-24 _x_items-center _x_justify-center _x_rounded-full _x_bg-dark-100 _x_text-10 _x_text-white"></i>
                        </ng-template>
                        <!-- auth mobile dropdown list /.-->

                        <div class="_x_overflow-hidden _x_text-center">
                          <!--User Name-->
                          <div class="_x_mb-4 _x_flex _x_items-center">
                            <h4 class="_x_font-medium _x_text-3 _x_text-dark">
                              <span class="_x_overflow-hidden _x_overflow-ellipsis _x_whitespace-nowrap"
                                >{{ user.firstName }} {{ user.lastName }}</span
                              >
                            </h4>
                            <i
                              *ngIf="userVerifiedStatus$ | async"
                              class="_x_icon-done-check _x_ml-4 _x_flex _x_h-8 _x_w-8 _x_items-center _x_justify-center _x_rounded-full _x_bg-green _x_text-4 _x_text-white"></i>
                          </div>
                          <!---->
                          <div class="_x_font-medium _x_text-2 _x_text-dark-600">
                            <!--user ID-->
                            <span class="">ID - {{ user.userExternalId }}</span>
                            <!--user rate-->
                          </div>
                        </div>
                      </div>
                      <!-- balance area -->
                      <div
                        class="_x_flex _x_items-center _x_justify-center _x_border-b _x_border-dark-200 _x_pb-12 _x_pt-8">
                        <button
                          [routerLink]="'/user/profile/balance'"
                          aria-label="visit profile balance page"
                          class="_x_mr-2 _x_flex _x_h-24 _x_items-center _x_justify-center _x_rounded-40 _x_border _x_border-dark-300 _x_px-8 _x_font-medium _x_text-dark _x_transition _x_duration-300 hover:_x_border-dark">
                          <span class="_x_mr-4 _x_font-medium _x_text-2 _x_text-black">{{
                            'TEXT.BALANCE' | translate
                          }}</span>
                          <span class="_x_font-bold _x_text-4 _x_text-dark">{{ user.balance }}</span>
                          <i
                            class="_x_icon-gel _x_icon _x_flex _x_h-8 _x_w-8 _x_min-w-8 _x_items-center _x_justify-center _x_pl-1 _x_text-5 _x_text-dark"></i>
                          <i
                            class="_x_icon-plus _x_icon _x_ml-4 _x_flex _x_h-12 _x_min-h-10 _x_w-10 _x_min-w-12 _x_items-center _x_justify-center _x_text-7 _x_text-dark"></i>
                        </button>
                      </div>
                      <!-- balance area /. -->

                      <!-- account atom dropdown layer -->

                      <ul class="_x_overflow-hidden _x_pt-5">
                        <li class="">
                          <a
                            aria-label="visit profile orders page"
                            [routerLink]="'/user/profile/orders'"
                            class="_x_text-decoration-none hover:_x_font-8 _x_group _x_flex _x_items-center _x_py-5">
                            <i
                              class="_x_icon-orders _x_mr-5 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_text-8 _x_text-dark-700 group-hover:_x_text-black"></i>
                            <span
                              class="_x_m-0 _x_font-medium _x_text-4 _x_text-dark-700 group-hover:_x_font-bold group-hover:_x_text-dark"
                              >ჩემი შეკვეთები</span
                            >
                          </a>
                        </li>
                        <li class="">
                          <a
                            aria-label="visit profile addresses page"
                            [routerLink]="'/user/profile/addresses'"
                            class="_x_text-decoration-none hover:_x_font-8 _x_group _x_flex _x_items-center _x_py-5">
                            <i
                              class="_x_icon-location _x_mr-5 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_text-8 _x_text-dark-700 group-hover:_x_text-black"></i>

                            <span
                              class="_x_m-0 _x_font-medium _x_text-4 _x_text-dark-700 group-hover:_x_font-bold group-hover:_x_text-dark"
                              >მისამართები</span
                            >
                          </a>
                        </li>
                        <li class="">
                          <a
                            aria-label="visit profile data page"
                            [routerLink]="'/user/profile/data'"
                            class="_x_text-decoration-none hover:_x_font-8 _x_group _x_flex _x_items-center _x_py-5">
                            <i
                              class="_x_icon-user-profile-1 _x_mr-5 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_text-8 _x_text-dark-700 group-hover:_x_text-black"></i>
                            <span
                              class="_x_m-0 _x_font-medium _x_text-4 _x_text-dark-700 group-hover:_x_font-bold group-hover:_x_text-dark">
                              პროფილი</span
                            >
                          </a>
                        </li>
                        <li class="">
                          <a
                            aria-label="visit profile my-cards page"
                            (click)="saveReferrer()"
                            [routerLink]="'/user/profile/my-cards'"
                            class="_x_text-decoration-none hover:_x_font-8 _x_group _x_flex _x_items-center _x_py-5">
                            <i
                              class="_x_icon-card _x_mr-5 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_text-8 _x_text-dark-700 group-hover:_x_text-black"></i>
                            <span
                              class="_x_m-0 _x_font-medium _x_text-4 _x_text-dark-700 group-hover:_x_font-bold group-hover:_x_text-dark"
                              >ბარათები
                            </span>
                          </a>
                        </li>
                        <li class="">
                          <a
                            aria-label="visit profile voucher-redemption page"
                            (click)="saveReferrer()"
                            [routerLink]="'/user/profile/voucher-redemption'"
                            class="_x_text-decoration-none hover:_x_font-8 _x_group _x_flex _x_items-center _x_py-5">
                            <i
                              class="_x_icon-gift-card-2 _x_mr-5 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_text-8 _x_text-dark-700 group-hover:_x_text-black"></i>
                            <span
                              class="_x_m-0 _x_font-medium _x_text-4 _x_text-dark-700 group-hover:_x_font-bold group-hover:_x_text-dark"
                              >ვაუჩერის განაღდება
                            </span>
                          </a>
                        </li>
                        <li *ngIf="!isUserLegalEntity" class="">
                          <a
                            aria-label="visit profile invite-friends page"
                            (click)="saveReferrer()"
                            [routerLink]="'/user/profile/invite-friends'"
                            class="_x_group _x_flex _x_items-center _x_rounded-8 _x_pr-5">
                            <i
                              class="_x_icon-ph_megaphone-bold _x_mr-5 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_text-8 _x_text-dark-700 group-hover:_x_text-black"></i>
                            <span
                              class="_x_text _x_h-17 _x_whitespace-nowrap _x_pt-2 _x_font-medium _x_text-4 _x_text-dark-700 group-hover:_x_font-bold group-hover:_x_text-dark"
                              >მოიწვიე მეგობრები
                            </span>
                          </a>
                        </li>
                        <li class="">
                          <a
                            aria-label="visit profile balance page"
                            (click)="saveReferrer()"
                            [routerLink]="'/user/profile/balance'"
                            class="_x_text-decoration-none hover:_x_font-8 _x_group _x_flex _x_items-center _x_py-5">
                            <i
                              class="_x_icon-wallet _x_mr-5 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_text-8 _x_text-dark-700 group-hover:_x_text-black"></i>
                            <span
                              class="_x_m-0 _x_font-medium _x_text-4 _x_text-dark-700 group-hover:_x_font-bold group-hover:_x_text-dark"
                              >ბალანსის შევსება</span
                            >
                          </a>
                        </li>
                        <li class="">
                          <a
                            aria-label="visit profile security page"
                            [routerLink]="'/user/profile/security'"
                            class="_x_text-decoration-none hover:_x_font-8 _x_group _x_flex _x_items-center _x_py-5">
                            <i
                              class="_x_icon-security _x_mr-5 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_text-8 _x_text-dark-700 group-hover:_x_text-black"></i>
                            <span
                              class="_x_m-0 _x_font-medium _x_text-4 _x_text-dark-700 group-hover:_x_font-bold group-hover:_x_text-dark"
                              >პაროლის შეცვლა</span
                            >
                          </a>
                        </li>
                        <li class="_x_mb-45 sm:_x_mb-0">
                          <a
                            aria-label="logout go to home page"
                            (click)="logout()"
                            class="_x_text-decoration-none hover:_x_font-8 _x_group _x_flex _x_items-center _x_py-5">
                            <i
                              class="_x_icon-logout _x_mr-5 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_text-8 _x_text-dark-700 group-hover:_x_text-dark"></i>
                            <span
                              class="_x_m-0 _x_font-medium _x_text-4 _x_text-dark-700 group-hover:_x_font-bold group-hover:_x_text-dark"
                              >გასვლა</span
                            >
                          </a>
                        </li>
                      </ul>

                      <!-- account atom dropdown layer /.-->
                    </div>
                    <!-- mobile user info and links list -->
                  </div>
                  <!-- auth mobile dropdown list /.-->
                </div>
                <!-- mobile dropdown end -->

                <!-- desktop dropdown -->
                <div
                  [isEnabled]="isProfileDropdownOpen"
                  clickOutside
                  (onClickOutside)="clickedOutside()"
                  *ngIf="(userStatus$ | async) && (user$ | async)"
                  class="_x_relative _x_hidden _x_h-full _x_cursor-pointer _x_items-center _x_justify-center md:_x_flex">
                  <!--user login-->
                  <div class="_x_flex _x_h-full _x_items-center _x_justify-center" (click)="toggleProfileDropdown()">
                    <div id="userData"></div>

                    <!-- useer info -->
                    <span class="_x_flex _x_items-center _x_outline-none">
                      <ng-container *ngIf="(userStatus$ | async) && (user$ | async) as user">
                        <figure
                          *ngIf="user.avatarUrl; else defIcon"
                          class="_x_mb-0 _x_ml-5 _x_flex _x_h-15 _x_w-15 _x_min-w-15 _x_items-center _x_justify-center _x_overflow-hidden _x_rounded-full xs:_x_ml-0 md:_x_h-24 md:_x_w-24 md:_x_min-w-24">
                          <img [src]="user.avatarUrl" alt="avatar icon" class="_x_max-w-full _x_max-h-full _x_w-full" />
                        </figure>
                        <ng-template #defIcon>
                          <i
                            class="_x_icon _x_icon-user-profile-1 _x_flex _x_h-24 _x_min-h-24 _x_w-24 _x_min-w-24 _x_items-center _x_justify-center _x_rounded-full _x_bg-dark-100 _x_text-10 _x_text-white"></i>
                        </ng-template>
                      </ng-container>
                    </span>
                    <!-- useer info /-->

                    <!-- account atom dropdown layer -->
                    <div
                      *ngIf="(user$ | async) && isProfileDropdownOpen"
                      class="_x_absolute _x_right-0 _x_top-full _x_z-2 _x_pt-5 _x_transition _x_duration-300 lg:_x_min-w-113">
                      <ul
                        class="_x_shadow-dark100 _x_overflow-hidden _x_rounded-12 _x_border _x_border-dark-100 _x_bg-white _x_pb-7 _x_pl-5 _x_pr-5 _x_pt-7 _x_shadow-default">
                        <li class="">
                          <a
                            aria-label="visit profile orders page"
                            [routerLink]="'/user/profile/orders'"
                            class="_x_group _x_flex _x_items-center _x_rounded-8 _x_pl-5 _x_pr-5 hover:_x_bg-dark-100">
                            <i
                              class="_x_icon-orders _x_mr-5 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_text-8 _x_text-dark-700 group-hover:_x_text-black"></i>
                            <span
                              class="_x_text _x_h-17 _x_whitespace-nowrap _x_pt-2 _x_font-medium _x_text-4 _x_text-dark"
                              >ჩემი შეკვეთები</span
                            >
                          </a>
                        </li>
                        <li class="">
                          <a
                            aria-label="visit profile addresses page"
                            [routerLink]="'/user/profile/addresses'"
                            class="_x_group _x_flex _x_items-center _x_rounded-8 _x_pl-5 _x_pr-5 hover:_x_bg-dark-100">
                            <i
                              class="_x_icon-location _x_mr-5 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_text-8 _x_text-dark-700 group-hover:_x_text-black"></i>
                            <span
                              class="_x_text _x_h-17 _x_whitespace-nowrap _x_pt-2 _x_font-medium _x_text-4 _x_text-dark"
                              >მისამართები</span
                            >
                          </a>
                        </li>
                        <li class="">
                          <a
                            aria-label="visit profile data page"
                            [routerLink]="'/user/profile/data'"
                            class="_x_group _x_flex _x_items-center _x_rounded-8 _x_pl-5 _x_pr-5 hover:_x_bg-dark-100">
                            <i
                              class="_x_icon-user-profile-1 _x_mr-5 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_text-8 _x_text-dark-700 group-hover:_x_text-black"></i>
                            <span
                              class="_x_text _x_h-17 _x_whitespace-nowrap _x_pt-2 _x_font-medium _x_text-4 _x_text-dark">
                              პროფილი</span
                            >
                          </a>
                        </li>
                        <li class="">
                          <a
                            aria-label="visit profile my-cards page"
                            (click)="saveReferrer()"
                            [routerLink]="'/user/profile/my-cards'"
                            class="_x_group _x_flex _x_items-center _x_rounded-8 _x_pl-5 _x_pr-5 hover:_x_bg-dark-100">
                            <i
                              class="_x_icon-card _x_mr-5 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_text-8 _x_text-dark-700 group-hover:_x_text-black"></i>
                            <span
                              class="_x_text _x_h-17 _x_whitespace-nowrap _x_pt-2 _x_font-medium _x_text-4 _x_text-dark"
                              >ბარათები
                            </span>
                          </a>
                        </li>
                        <li class="">
                          <a
                            aria-label="visit profile balance page"
                            (click)="saveReferrer()"
                            [routerLink]="'/user/profile/balance'"
                            class="_x_group _x_flex _x_items-center _x_rounded-8 _x_pl-5 _x_pr-5 hover:_x_bg-dark-100">
                            <i
                              class="_x_icon-wallet _x_mr-5 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_text-8 _x_text-dark-700 group-hover:_x_text-black"></i>
                            <span
                              class="_x_text _x_h-17 _x_whitespace-nowrap _x_pt-2 _x_font-medium _x_text-4 _x_text-dark"
                              >ბალანსის შევსება</span
                            >
                          </a>
                        </li>
                        <li *ngIf="!isUserLegalEntity" class="">
                          <a
                            aria-label="visit profile invite-friends page"
                            (click)="saveReferrer()"
                            [routerLink]="'/user/profile/invite-friends'"
                            class="_x_group _x_flex _x_items-center _x_rounded-8 _x_pl-5 _x_pr-5 hover:_x_bg-dark-100">
                            <i
                              class="_x_icon-ph_megaphone-bold _x_mr-5 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_text-8 _x_text-dark-700 group-hover:_x_text-black"></i>
                            <span
                              class="_x_text _x_h-17 _x_whitespace-nowrap _x_pt-2 _x_font-medium _x_text-4 _x_text-dark"
                              >მოიწვიე მეგობრები
                            </span>
                          </a>
                        </li>
                        <li class="">
                          <a
                            aria-label="visit profile voucher-redemption page"
                            [routerLink]="'/user/profile/voucher-redemption'"
                            class="_x_group _x_flex _x_items-center _x_rounded-8 _x_pl-5 _x_pr-5 hover:_x_bg-dark-100">
                            <i
                              class="_x_icon-gift-card-2 _x_mr-5 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_text-8 _x_text-dark-700 group-hover:_x_text-black"></i>
                            <span
                              class="_x_text _x_h-17 _x_whitespace-nowrap _x_pt-2 _x_font-medium _x_text-4 _x_text-dark"
                              >ვაუჩერის განაღდება</span
                            >
                          </a>
                        </li>
                        <li class="">
                          <a
                            aria-label="visit profile security page"
                            [routerLink]="'/user/profile/security'"
                            class="_x_group _x_flex _x_items-center _x_rounded-8 _x_pl-5 _x_pr-5 hover:_x_bg-dark-100">
                            <i
                              class="_x_icon-security _x_mr-5 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_text-8 _x_text-dark-700 group-hover:_x_text-black"></i>
                            <span
                              class="_x_text _x_h-17 _x_whitespace-nowrap _x_pt-2 _x_font-medium _x_text-4 _x_text-dark"
                              >პაროლის შეცვლა</span
                            >
                          </a>
                        </li>
                        <li class="">
                          <a
                            aria-label="logout go to home page"
                            (click)="logout()"
                            class="_x_group _x_flex _x_items-center _x_rounded-8 _x_pl-5 _x_pr-5 hover:_x_bg-dark-100">
                            <i
                              class="_x_icon-logout _x_mr-5 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_text-8 _x_text-dark-700 group-hover:_x_text-dark"></i>
                            <span
                              class="_x_text _x_h-17 _x_whitespace-nowrap _x_pt-2 _x_font-medium _x_text-4 _x_text-dark"
                              >გასვლა</span
                            >
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- account atom dropdown layer /.-->
                  </div>
                </div>
                <!-- desktop dropdown end-->
              </div>
              <!--  right bar /.-->
            </div>
            <!-- search mobile -->
            <div *ngIf="isVisible" [formGroup]="searchForm" class="_x_relative _x_flex _x_w-full md:_x_hidden">
              <div class="_x_relative _x_flex _x_w-full _x_items-center _x_overflow-hidden md:_x_hidden">
                <input
                  #inputMobile
                  (focus)="onfocus()"
                  (focusout)="focusOutMobile()"
                  (keyup.enter)="search(searchForm.get('searchKeyword').value, undefined, $event)"
                  [ngClass]="{
                    '_x_pl-40': (selectUploadedImageForSearch | async),
                    '_x_pl-8': !(selectUploadedImageForSearch | async),
                    '_x_pr-[46px]': getSearchInputValue,
                    '_x_pl-60': showExpressSearch
                  }"
                  [placeholder]="showExpressSearch ? 'მოძებნე ექსპრესში' : 'მოძებნე რაც გაგიხარდება'"
                  class="_x_h-full _x_w-full _x_overflow-ellipsis _x_rounded-l-8 _x_rounded-r-none _x_border-2 _x_border-r-0 _x_border-purple _x_pr-18 _x_font-regular _x_text-3 _x_text-dark-700 _x_outline-none"
                  id="inputMobile"
                  name="search"
                  formControlName="searchKeyword"
                  type="text" />
                <div
                  *ngIf="selectUploadedImageForSearch | async"
                  class="_x_absolute _x_bottom-0 _x_left-0 _x_top-0 _x_m-2 _x_ml-3 _x_flex _x_h-19 _x_max-h-full _x_items-center _x_rounded-4 _x_bg-dark-100 _x_p-2">
                  <img
                    [alt]="(selectUploadedImageForSearch | async).externalId"
                    [src]="(selectUploadedImageForSearch | async).externalUrl"
                    class="_x_mr-2 _x_max-h-15 _x_w-15 _x_rounded-8" />
                  <i
                    (click)="clearUploadedSearchImage()"
                    class="_x_icon _x_icon-remove-bin _x_flex _x_h-12 _x_max-h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_cursor-pointer _x_items-center _x_justify-center _x_text-6 _x_text-dark-500"></i>
                </div>
                <div class="_x_absolute _x_right-[64px]">
                  <i
                    (click)="clearSearch()"
                    *ngIf="searchForm.get('searchKeyword').value"
                    class="_x_icon _x_icon-remove _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_cursor-pointer _x_items-center _x_justify-center _x_text-6 _x_text-dark-500"></i>
                </div>
                <!--                <button-->
                <!--                  (click)="!showExpressSearch && setImageSearchModalState(true)"-->
                <!--                  [disabled]="showExpressSearch"-->
                <!--                  aria-label="search button"-->
                <!--                  class="_x_absolute _x_right-20 _x_z-1">-->
                <!--                  <i-->
                <!--                    class="_x_icon _x_icon-camera _x_mr-10 _x_flex _x_h-12 _x_max-h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_cursor-pointer _x_items-center _x_text-7 _x_text-dark-700"></i>-->
                <!--                </button>-->
                <button
                  (click)="search(userTypedValue, undefined, $event)"
                  aria-label="search button"
                  class="_x_z-2 _x_flex _x_h-24 _x_items-center _x_justify-center _x_rounded-r-8 _x_border-2 _x_border-purple _x_bg-purple _x_px-6 _x_font-medium _x_text-white _x_transition _x_duration-300 hover:_x_bg-purple-100 hover:_x_text-purple">
                  <i
                    class="_x_icon _x_icon-search _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_justify-center _x_text-7"></i>
                </button>
              </div>

              <!-- search suggestions mobile -->
              <div
                *ngIf="showSuggestions"
                role="listbox"
                aria-labelledby="inputMobile"
                class="_x_shadow _x_absolute _x_left-0 _x_top-26 _x_z-10 _x_flex _x_w-full _x_flex-col _x_gap-4 _x_rounded-12 _x_border _x_border-dark-100 _x_bg-white _x_p-8">
                <a
                  (click)="search(userTypedValue, elem, $event)"
                  *ngFor="let elem of suggestions"
                  class="_x_flex _x_w-full _x_gap-4 _x_rounded-4 _x_font-medium _x_text-3 _x_text-dark"
                  role="option">
                  <i class="_x_icon _x_icon-search _x_text-4 _x_text-dark-500"></i>
                  <span class="_x_mt-[-2px]">
                    {{ getFirstLettersOfUserInput(elem)
                    }}<span class="_x_font-bold">{{ getLastLettersOfUserInput(elem) }}</span>
                  </span>
                </a>
              </div>
              <!-- search suggestions mobile/.-->

              <!--  search history mobile -->
              <!--              <div-->
              <!--                *ngIf="showSuggestions"-->
              <!--                role="listbox"-->
              <!--                aria-labelledby="historyMobile"-->
              <!--                class="_x_shadow _x_absolute _x_left-0 _x_top-26 _x_z-10 _x_flex _x_w-full _x_flex-col _x_gap-4 _x_rounded-12 _x_border _x_border-dark-100 _x_bg-white _x_p-8">-->
              <!--                <div class="_x_mb-2 _x_flex _x_items-center _x_justify-between _x_gap-10">-->
              <!--                  <h4 class="_x_font-bold _x_text-3 _x_text-dark">ძიების ისტორია</h4>-->
              <!--                  <button class="_x_font-medium _x_text-2 _x_text-link-blue">გასუფთავება</button>-->
              <!--                </div>-->
              <!--                <div *ngFor="let elem of suggestions" class="_x_flex _x_items-start _x_justify-between _x_gap-10">-->
              <!--                  <a-->
              <!--                    (click)="search(userTypedValue, elem, $event)"-->
              <!--                    class="_x_flex _x_w-full _x_gap-4 _x_rounded-4 _x_font-medium _x_text-3 _x_text-dark"-->
              <!--                    role="option">-->
              <!--                    <i class="_x_icon _x_icon-history _x_text-4 _x_text-dark-500"></i>-->
              <!--                    <span class="_x_mt-[-2px]">-->
              <!--                      {{ getFirstLettersOfUserInput(elem)-->
              <!--                      }}<span class="_x_font-bold">{{ getLastLettersOfUserInput(elem) }}</span>-->
              <!--                    </span>-->
              <!--                  </a>-->

              <!--                  <button class="_x_mt-[-2px]">-->
              <!--                    <i class="_x_icon-remove _x_h-6 _x_w-6 _x_text-3 _x_text-dark-700"></i>-->
              <!--                  </button>-->
              <!--                </div>-->
              <!--              </div>-->
              <!--  search history mobile  -->
            </div>
            <!-- search mobile -->
          </div>
          <div *ngIf="showCategory" [ngStyle]="{ width: scrollbarWidth }"></div>
        </div>
        <!-- top head /. -->

        <!-- sub head -->
        <section
          *ngIf="showSecondLevelHeader"
          class="_x_min-h-26 _x_flex _x_w-full _x_items-center _x_bg-white _x_px-8">
          <nav
            class="category-custom-scroll-bar _x_m-auto _x_flex _x_w-full _x_max-w-765 _x_justify-start _x_gap-4 _x_overflow-x-auto sm:_x_scrollbar-hide">
            <!-- catalog -->
            <button
              (click)="openCategories(true)"
              [ngClass]="{ '_x_bg-purple _x_pl-5 _x_text-white hover:_x_text-white': showCategory }"
              aria-label="open categories"
              class="_x_hidden _x_h-20 _x_items-center _x_justify-center _x_rounded-8 _x_pr-5 _x_font-medium _x_text-darkBlue _x_transition _x_duration-300 hover:_x_text-purple md:_x_flex"
              id="catalogBtn">
              <i
                [ngClass]="showCategory ? '_x_icon-remove' : '_x_icon-burger'"
                class="_x_icon _x_flex _x_h-10 _x_min-h-10 _x_w-10 _x_min-w-10 _x_items-center _x_justify-center _x_text-6"></i>
              <span class="_x_ml-4 _x_font-bold _x_text-3">კატალოგი</span>
            </button>

            <div
              [ngClass]="{
                '_x_scrollbar-w-10 _x_scrollbar-thumb-rounded-8 _x_flex _x_items-center _x_overflow-x-auto _x_scrollbar-thumb-dark-200 _x_scrollbar-track-transparent':
                  hidePopInfo,
                _x_invisible: showCategory
              }"
              class="_x_flex _x_w-full _x_items-center _x_justify-between _x_pb-[11px] _x_pt-5 md:_x_py-0">
              <ul
                class="_x_flex _x_h-full _x_w-full _x_items-center _x_justify-start _x_gap-5 sm:_x_gap-9 md:_x_justify-between xl:_x_justify-start">
                <li class="_x_pr-[5px]">
                  <app-express-button class="_x_flex"></app-express-button>
                </li>

                <li>
                  <a
                    aria-label="visit gatanis-tsertili page"
                    class="_ _x_flex _x_cursor-pointer _x_items-center _x_gap-2 _x_whitespace-nowrap _x_rounded-100 _x_bg-[#E933971A] _x_px-4 _x_py-3 _x_font-medium _x_text-3 _x_text-dark"
                    routerLink="/static/gatanis-tsertili">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12.523 2.94519C12.9556 3.43962 13.5422 3.71783 14.154 3.71879C14.2193 3.71879 14.2818 3.74842 14.328 3.80115C14.3741 3.85389 14.4 3.92541 14.4 3.99999C14.4 4.07456 14.3741 4.14609 14.328 4.19882C14.2818 4.25156 14.2193 4.28118 14.154 4.28118C13.5422 4.28215 12.9556 4.56035 12.523 5.05479C12.0904 5.54923 11.8469 6.21955 11.8461 6.91879C11.8461 6.99337 11.8202 7.06489 11.774 7.11763C11.7279 7.17036 11.6653 7.19999 11.6 7.19999C11.5348 7.19999 11.4722 7.17036 11.4261 7.11763C11.3799 7.06489 11.354 6.99337 11.354 6.91879C11.3532 6.21955 11.1097 5.54923 10.6771 5.05479C10.2445 4.56035 9.65793 4.28215 9.04609 4.28118C8.98084 4.28118 8.91826 4.25156 8.87211 4.19882C8.82597 4.14609 8.80005 4.07456 8.80005 3.99999C8.80005 3.92541 8.82597 3.85389 8.87211 3.80115C8.91826 3.74842 8.98084 3.71879 9.04609 3.71879C9.65793 3.71783 10.2445 3.43962 10.6771 2.94519C11.1097 2.45075 11.3532 1.78042 11.354 1.08118C11.354 1.00661 11.3799 0.935082 11.4261 0.882348C11.4722 0.829613 11.5348 0.799988 11.6 0.799988C11.6653 0.799988 11.7279 0.829613 11.774 0.882348C11.8202 0.935082 11.8461 1.00661 11.8461 1.08118C11.8469 1.78042 12.0904 2.45075 12.523 2.94519Z"
                        fill="#E93397" />
                      <path
                        d="M12.2755 11.1894C12.57 11.5428 12.9694 11.7416 13.386 11.7419C13.4427 11.7419 13.4972 11.769 13.5373 11.8171C13.5774 11.8653 13.6 11.9306 13.6 11.9987C13.6 12.0668 13.5774 12.1322 13.5373 12.1803C13.4972 12.2285 13.4427 12.2555 13.386 12.2555C12.9692 12.2564 12.5697 12.4557 12.2752 12.8096C11.9807 13.1634 11.8152 13.6431 11.8151 14.1432C11.8151 14.2113 11.7925 14.2766 11.7524 14.3248C11.7122 14.3729 11.6578 14.4 11.601 14.4C11.5443 14.4 11.4898 14.3729 11.4497 14.3248C11.4096 14.2766 11.387 14.2113 11.387 14.1432C11.3863 13.6428 11.2204 13.1631 10.9255 12.8093C10.6307 12.4555 10.231 12.2564 9.81399 12.2555C9.75723 12.2555 9.7028 12.2285 9.66266 12.1803C9.62252 12.1322 9.59998 12.0668 9.59998 11.9987C9.59998 11.9306 9.62252 11.8653 9.66266 11.8171C9.7028 11.769 9.75723 11.7419 9.81399 11.7419C10.2308 11.7417 10.6305 11.5431 10.9254 11.1897C11.2203 10.8363 11.3863 10.357 11.387 9.85683C11.387 9.78871 11.4096 9.72339 11.4497 9.67523C11.4898 9.62706 11.5443 9.60001 11.601 9.60001C11.6578 9.60001 11.7122 9.62706 11.7524 9.67523C11.7925 9.72339 11.8151 9.78871 11.8151 9.85683C11.8153 10.3567 11.9809 10.836 12.2755 11.1894Z"
                        fill="#E93397" />
                      <path
                        d="M5.60002 12C5.6602 11.9998 5.71786 11.9698 5.76042 11.9166C5.80297 11.8635 5.82696 11.7914 5.82714 11.7162C5.82798 10.8063 6.11746 9.93394 6.63215 9.29028C7.14683 8.64662 7.84474 8.28417 8.57291 8.28237C8.63315 8.28237 8.69091 8.25247 8.7335 8.19925C8.7761 8.14603 8.80002 8.07385 8.80002 7.99858C8.80002 7.92332 8.7761 7.85113 8.7335 7.79791C8.69091 7.74469 8.63315 7.71479 8.57291 7.71479C7.84498 7.71359 7.14714 7.35173 6.63242 6.70855C6.11769 6.06537 5.8281 5.19338 5.82714 4.28379C5.82714 4.20852 5.80321 4.13634 5.76062 4.08312C5.71802 4.0299 5.66026 4 5.60002 4C5.53979 4 5.48202 4.0299 5.43943 4.08312C5.39684 4.13634 5.37291 4.20852 5.37291 4.28379C5.37231 5.19352 5.08283 6.06577 4.56803 6.70904C4.05323 7.35232 3.35518 7.71404 2.62714 7.71479C2.5669 7.71479 2.50914 7.74469 2.46654 7.79791C2.42395 7.85113 2.40002 7.92332 2.40002 7.99858C2.40002 8.07385 2.42395 8.14603 2.46654 8.19925C2.50914 8.25247 2.5669 8.28237 2.62714 8.28237C3.35542 8.28372 4.05354 8.64603 4.5683 9.28978C5.08306 9.93354 5.37243 10.8062 5.37291 11.7162C5.37309 11.7914 5.39708 11.8635 5.43963 11.9166C5.48218 11.9698 5.53985 11.9998 5.60002 12Z"
                        fill="#E93397" />
                    </svg>

                    გატანის წერტილი

                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M3.477 2.94519C3.04437 3.43962 2.45783 3.71783 1.846 3.71879C1.78074 3.71879 1.71816 3.74842 1.67202 3.80115C1.62587 3.85389 1.59995 3.92541 1.59995 3.99999C1.59995 4.07456 1.62587 4.14609 1.67202 4.19882C1.71816 4.25156 1.78074 4.28118 1.846 4.28118C2.45783 4.28215 3.04437 4.56035 3.477 5.05479C3.90963 5.54923 4.15306 6.21955 4.15391 6.91879C4.15391 6.99337 4.17983 7.06489 4.22597 7.11763C4.27211 7.17036 4.3347 7.19999 4.39995 7.19999C4.46521 7.19999 4.52779 7.17036 4.57393 7.11763C4.62007 7.06489 4.646 6.99337 4.646 6.91879C4.64684 6.21955 4.89027 5.54923 5.3229 5.05479C5.75554 4.56035 6.34207 4.28215 6.95391 4.28118C7.01916 4.28118 7.08174 4.25156 7.12789 4.19882C7.17403 4.14609 7.19995 4.07456 7.19995 3.99999C7.19995 3.92541 7.17403 3.85389 7.12789 3.80115C7.08174 3.74842 7.01916 3.71879 6.95391 3.71879C6.34207 3.71783 5.75554 3.43962 5.3229 2.94519C4.89027 2.45075 4.64684 1.78042 4.646 1.08118C4.646 1.00661 4.62007 0.935082 4.57393 0.882348C4.52779 0.829613 4.46521 0.799988 4.39995 0.799988C4.3347 0.799988 4.27211 0.829613 4.22597 0.882348C4.17983 0.935082 4.15391 1.00661 4.15391 1.08118C4.15306 1.78042 3.90963 2.45075 3.477 2.94519Z"
                        fill="#E93397" />
                      <path
                        d="M3.72452 11.1894C3.42998 11.5428 3.03058 11.7416 2.61404 11.7419C2.55728 11.7419 2.50285 11.769 2.46271 11.8171C2.42257 11.8653 2.40002 11.9306 2.40002 11.9987C2.40002 12.0668 2.42257 12.1322 2.46271 12.1803C2.50285 12.2285 2.55728 12.2555 2.61404 12.2555C3.03081 12.2564 3.43027 12.4557 3.72477 12.8096C4.01927 13.1634 4.18477 13.6431 4.18494 14.1432C4.18494 14.2113 4.20748 14.2766 4.24762 14.3248C4.28776 14.3729 4.34219 14.4 4.39895 14.4C4.45572 14.4 4.51015 14.3729 4.55029 14.3248C4.59042 14.2766 4.61297 14.2113 4.61297 14.1432C4.61365 13.6428 4.7796 13.1631 5.07445 12.8093C5.36931 12.4555 5.76902 12.2564 6.18601 12.2555C6.24277 12.2555 6.2972 12.2285 6.33734 12.1803C6.37748 12.1322 6.40002 12.0668 6.40002 11.9987C6.40002 11.9306 6.37748 11.8653 6.33734 11.8171C6.2972 11.769 6.24277 11.7419 6.18601 11.7419C5.76923 11.7417 5.36955 11.5431 5.07465 11.1897C4.77974 10.8363 4.61371 10.357 4.61297 9.85683C4.61297 9.78871 4.59042 9.72339 4.55029 9.67523C4.51015 9.62706 4.45572 9.60001 4.39895 9.60001C4.34219 9.60001 4.28776 9.62706 4.24762 9.67523C4.20748 9.72339 4.18494 9.78871 4.18494 9.85683C4.18465 10.3567 4.01906 10.836 3.72452 11.1894Z"
                        fill="#E93397" />
                      <path
                        d="M10.4 12C10.3398 11.9998 10.2821 11.9698 10.2396 11.9166C10.197 11.8635 10.173 11.7914 10.1729 11.7162C10.172 10.8063 9.88254 9.93394 9.36785 9.29028C8.85317 8.64662 8.15526 8.28417 7.42709 8.28237C7.36685 8.28237 7.30909 8.25247 7.2665 8.19925C7.2239 8.14603 7.19998 8.07385 7.19998 7.99858C7.19998 7.92332 7.2239 7.85113 7.2665 7.79791C7.30909 7.74469 7.36685 7.71479 7.42709 7.71479C8.15502 7.71359 8.85286 7.35173 9.36758 6.70855C9.88231 6.06537 10.1719 5.19338 10.1729 4.28379C10.1729 4.20852 10.1968 4.13634 10.2394 4.08312C10.282 4.0299 10.3397 4 10.4 4C10.4602 4 10.518 4.0299 10.5606 4.08312C10.6032 4.13634 10.6271 4.20852 10.6271 4.28379C10.6277 5.19352 10.9172 6.06577 11.432 6.70904C11.9468 7.35232 12.6448 7.71404 13.3729 7.71479C13.4331 7.71479 13.4909 7.74469 13.5335 7.79791C13.576 7.85113 13.6 7.92332 13.6 7.99858C13.6 8.07385 13.576 8.14603 13.5335 8.19925C13.4909 8.25247 13.4331 8.28237 13.3729 8.28237C12.6446 8.28372 11.9465 8.64603 11.4317 9.28978C10.9169 9.93354 10.6276 10.8062 10.6271 11.7162C10.6269 11.7914 10.6029 11.8635 10.5604 11.9166C10.5178 11.9698 10.4602 11.9998 10.4 12Z"
                        fill="#E93397" />
                    </svg>
                  </a>
                </li>

                <li>
                  <a
                    aria-label="visit discount page"
                    [queryParams]="{ filterByDiscount: true }"
                    class="_x_inline-block _x_cursor-pointer _x_whitespace-nowrap _x_p-[5px] _x_font-medium _x_text-3 _x_text-dark _x_transition _x_duration-300 hover:_x_text-purple"
                    routerLink="/discount">
                    ფასდაკლებები
                  </a>
                </li>

                <a
                  aria-label="visit express page"
                  (click)="setExpressSearchCase()"
                  class="_x_invisible _x_absolute _x_inline-block _x_w-0 _x_cursor-pointer _x_whitespace-nowrap _x_p-[5px] _x_font-medium _x_text-3 _x_text-dark _x_transition _x_duration-300 hover:_x_text-purple"
                  id="expressBtnPlain"
                  routerLink="/express"
                  >ექსპრესი</a
                >

                <li>
                  <a
                    aria-label="visit voucher page"
                    class="_x_inline-block _x_cursor-pointer _x_whitespace-nowrap _x_p-[5px] _x_font-medium _x_text-3 _x_text-dark _x_transition _x_duration-300 hover:_x_text-purple"
                    routerLink="/voucher"
                    >ვაუჩერი</a
                  >
                </li>

                <li>
                  <a
                    aria-label="visit shops page"
                    class="_x_inline-block _x_cursor-pointer _x_whitespace-nowrap _x_p-[5px] _x_font-medium _x_text-3 _x_text-dark _x_transition _x_duration-300 hover:_x_text-purple"
                    routerLink="/sellers"
                    >მაღაზიები</a
                  >
                </li>

                <li class="_x_align-center _x_relative _x_flex _x_justify-center">
                  <a
                    aria-label="visit popular page"
                    [routerLink]="['/', 'catalog', 'set', 'popular', popularProductsId]"
                    class="_x_inline-block _x_whitespace-nowrap _x_p-[5px] _x_font-medium _x_text-3 _x_text-dark _x_transition _x_duration-300 hover:_x_text-purple"
                    >პოპულარული
                  </a>
                </li>

                <li>
                  <a
                    aria-label="visit toys page"
                    class="_x_inline-block _x_whitespace-nowrap _x_p-[5px] _x_font-medium _x_text-3 _x_text-dark _x_transition _x_duration-300 hover:_x_text-purple"
                    routerLink="/catalog/satamashoebi/1386"
                    >სათამაშოები</a
                  >
                </li>

                <li>
                  <a
                    aria-label="visit technik page"
                    class="_x_inline-block _x_whitespace-nowrap _x_p-[5px] _x_font-medium _x_text-3 _x_text-dark _x_transition _x_duration-300 hover:_x_text-purple"
                    routerLink="/catalog/teqnika/1"
                    >ტექნიკა</a
                  >
                </li>

                <li>
                  <a
                    aria-label="visit decor page"
                    class="_x_inline-block _x_whitespace-nowrap _x_p-[5px] _x_font-medium _x_text-3 _x_text-dark _x_transition _x_duration-300 hover:_x_text-purple sm:_x_hidden md:_x_block"
                    routerLink="/catalog/sakhli-dekori-aveji/8"
                    >სახლი დეკორი ავეჯი</a
                  >
                </li>

                <li>
                  <a
                    aria-label="visit Beauty and self care page"
                    class="_x_inline-block _x_whitespace-nowrap _x_p-[5px] _x_font-medium _x_text-3 _x_text-dark _x_transition _x_duration-300 hover:_x_text-purple sm:_x_hidden xl:_x_block"
                    routerLink="/catalog/silamaze-da-tavis-movla/176"
                    >სილამაზე და თავის მოვლა</a
                  >
                </li>
              </ul>
            </div>
          </nav>
        </section>
      </div>
      <div class="_x_top-0 _x_w-full _x_bg-white">
        <ng-template appDynamicHost></ng-template>
      </div>
    </div>
  </header>
</div>

<!--header /.-->

<ng-container *ngIf="imageSearchModalState | async">
  <div class="_x_fixed _x_z-10 _x_h-screen _x_w-full"></div>
  <div
    class="_x_fixed _x_top-0 _x_z-10 _x_m-auto _x_flex _x_h-screen _x_w-full _x_items-center _x_justify-center _x_bg-dark-900 _x_p-10">
    <div
      class="_x_relative _x_bottom-0 _x_left-0 _x_right-0 _x_top-0 _x_z-10 _x_m-auto _x_my-auto _x_flex _x_max-h-174 _x_max-w-243 _x_select-none _x_items-center _x_justify-center _x_rounded-8 _x_bg-white md:_x_mx-auto">
      <div
        class="_x_relative _x_mx-10 _x_mb-15 _x_mt-30 _x_flex _x_h-full _x_w-full _x_flex-col _x_items-center _x_justify-center _x_rounded-8 _x_border-2 _x_border-dashed _x_border-purple _x_px-22 _x_py-15 md:_x_mx-25 md:_x_my-25 md:_x_py-20"
        imageForSearch>
        <input
          (change)="searchByImage($event)"
          accept="image/jpg, image/jpeg, image/png"
          class="_x_absolute _x_top-0 _x_z-10 _x_hidden _x_h-full _x_w-full _x_opacity-0"
          id="uploadImage"
          type="file" />
        <button
          aria-label="add similar product image"
          class="_x_flex _x_items-center _x_justify-center _x_rounded-full _x_bg-purple _x_p-7">
          <i class="_x_icon _x_icon-camera _x_cursor-pointer _x_text-7 _x_text-white"></i>
        </button>
        <p class="_x_my-10 _x_text-center _x_font-medium _x_text-3 _x_text-dark">
          ატვირთე ფოტო მსგავსი პროდუქციის სანახავად
        </p>
        <label
          *ngIf="!(selectImageForSearchIsUploading | async); else loader"
          class="_x_group _x_mb-4 _x_flex _x_h-21 _x_w-full _x_max-w-78 _x_cursor-pointer _x_items-center _x_justify-center _x_rounded-8 _x_bg-purple-100"
          for="uploadImage">
          <span class="_x_font-semibold _x_text-3 _x_text-purple">ფოტოს არჩევა</span>
        </label>
        <ng-template #loader>
          <i class="_x_mb-11" id="countdown">
            <svg class="loader">
              <circle class="loader-circle" cx="14" cy="14" r="12"></circle>
            </svg>
          </i>
        </ng-template>
      </div>
      <button
        (click)="setImageSearchModalState(false)"
        aria-label="clear button"
        class="_x_absolute _x_right-10 _x_top-10 _x_flex _x_items-center _x_justify-center">
        <i class="_x_icon _x_icon-remove _x_cursor-pointer _x_text-4 _x_text-dark"></i>
      </button>
    </div>
  </div>
</ng-container>
