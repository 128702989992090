import { Pipe, PipeTransform } from '@angular/core';
import { VoucherType, VoucherTypeName } from 'projects/lib-core/src/lib/models/vouchers.model';

@Pipe({
  name: 'voucherName'
})
export class VoucherNamePipe implements PipeTransform {
  transform(voucherType: VoucherType): VoucherTypeName {
    switch (voucherType) {
      case VoucherType.electric:
        return VoucherTypeName.electric;
      case VoucherType.phisical:
        return VoucherTypeName.phisical;
      case VoucherType.phisicalAndAlsoElectric:
        return VoucherTypeName.phisical;
    }
  }
}
