import { Injectable } from '@angular/core';
import { OrderingHttp } from 'lib-core';
import { BehaviorSubject, filter, Observable } from 'rxjs';
import { GlobalModalService } from '../../services/global-modal-service';
import { UserFeedbackComponent } from './user-feedback.component';

export interface ReviewQuestionsInterface {
  question: string;
  skippable: boolean;
  description: string;
  deliver: number;
  answerType: number;
  sortIndex: number;
  id: number;
  orderExternalId: number;
}
export enum ReviewAnswerType {
  Rating = 1,
  Text
}
export enum feedbackView {
  feedback = 1,
  orderDetails,
  error,
  success
}

@Injectable({
  providedIn: 'root'
})
export class UserFeedbackService {
  private orderId: string;
  private fromHome: boolean;
  private orderExternalId: string;
  private reviewQuestions$ = new BehaviorSubject<{
    answered: number;
    reviewQuestions: ReviewQuestionsInterface[];
  }>({
    answered: 0,
    reviewQuestions: []
  });
  public feedbackView: feedbackView = feedbackView.feedback;
  constructor(
    private orderingHttp: OrderingHttp,
    private globalModalService: GlobalModalService
  ) {}

  public setOrderId(orderId: string, orderExternalId: string): void {
    this.resetValues();
    this.orderId = orderId;
    this.orderExternalId = orderExternalId;
    this.getReviewQuestions();
  }

  public setFromHome(): void {
    this.resetValues();
    this.fromHome = true;
    this.getReviewQuestions();
  }

  public getReviewQuestions(): void {
    this.orderingHttp.getReviewQuestions(this.orderId).subscribe({
      next: (res: any) => {
        this.reviewQuestions$.next(res);
        if (this.fromHome && res) {
          this.orderId = res.reviewQuestions?.[0]?.orderId;
          this.orderExternalId = res.reviewQuestions?.[0]?.orderExternalId;
          this.globalModalService.open(UserFeedbackComponent, null, {
            fromHome: true
          });
        }
      }
    });
  }

  public getOrderId(): string {
    return this.orderId;
  }

  public getOrderExternalId(): string {
    return this.orderExternalId;
  }

  public setFeedbackView(view: feedbackView) {
    this.feedbackView = view;
  }

  public toggleFeedbackView() {
    this.feedbackView = this.feedbackView === feedbackView.feedback ? feedbackView.orderDetails : feedbackView.feedback;
  }
  public getFeedbackView(): feedbackView {
    return this.feedbackView;
  }

  getReviewQuestions$(): Observable<{
    answered: number;
    reviewQuestions: ReviewQuestionsInterface[];
  }> {
    return this.reviewQuestions$.asObservable();
  }

  private resetValues() {
    this.orderId = '';
    this.orderExternalId = '';
    this.fromHome = false;
    this.reviewQuestions$.next({
      answered: 0,
      reviewQuestions: []
    });
    this.feedbackView = feedbackView.feedback;
  }
}
