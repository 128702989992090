import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { OrderingHttp } from 'lib-core';
import { CampaignChannel } from 'projects/cms/src/app/routes/shell/campaigns/campaigns.model';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { GetCampaignsAttempt, GetCampaignsFailed, GetCampaignsSuccess } from '../actions/campaigns.actions';
import { CampaignsDataModel } from '../models/campaigns.model';

@Injectable()
export class CampaignsEffects {
  constructor(
    private actions$: Actions,
    private orderingHttp: OrderingHttp
  ) {}

  // getCampaignsAttempt = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(GetCampaignsAttempt),
  //     mergeMap(() =>
  //       this.orderingHttp.getActiveCampaigns().pipe(
  //         map((res: CampaignsDataModel) => {
  //           if (res) {
  //             res.activeCampaigns = res.activeCampaigns?.filter(item => item.campaignChannel !== CampaignChannel.App);
  //           }
  //           return GetCampaignsSuccess(res);
  //         }),
  //         catchError(err => of(GetCampaignsFailed(err)))
  //       )
  //     )
  //   )
  // );
}
