import {
  OrderFilterForCustomerType,
  OrderForCustomerViewType,
  OrderLineForCustomerViewType,
  PaymentType
} from '../store/models/orders.model';

export const ORDERLINE_STATUS_NAMING = {
  [OrderLineForCustomerViewType.Recived]: 'მივიღეთ',
  [OrderLineForCustomerViewType.Waiting]: 'ველოდებით',
  [OrderLineForCustomerViewType.Process]: 'ვამუშავებთ',
  [OrderLineForCustomerViewType.Collect]: 'ვაგროვებთ',
  [OrderLineForCustomerViewType.Prepare]: 'ვამზადებთ',
  [OrderLineForCustomerViewType.Bring]: 'მოგვაქვს',
  [OrderLineForCustomerViewType.DeliveredC]: 'ჩაბარებულია',
  [OrderLineForCustomerViewType.DeliveredP]: 'ფოსტაშია',
  [OrderLineForCustomerViewType.Cancelled]: 'გაუქმებულია',
  [OrderLineForCustomerViewType.DeliveredAtPickup]: 'გატანის წერტილშია'
};

export const MY_ORDERS_PAYMENT_METHODS = [
  {
    icon: [],
    label: 'განვადება',
    status: false,
    value: PaymentType.Instalmets,
    externalOpen: true
  },
  {
    icon: [],
    label: 'ნაწილ-ნაწილ',
    status: false,
    value: PaymentType.PartByPart,
    externalOpen: false
  },
  {
    icon: [],
    label: 'ბარათით',
    status: false,
    value: PaymentType.IPay,
    externalOpen: false
  },
  {
    icon: [],
    label: 'კურიერთან',
    status: false,
    value: PaymentType.CashOnHand,
    externalOpen: false
  },
  {
    icon: [],
    label: 'Mastercard',
    status: false,
    value: PaymentType.MasterCard,
    browserCheck: false,
    externalOpen: false,
    show: false
  },
  {
    icon: [],
    label: 'ბალანსით',
    status: false,
    value: PaymentType.Balance,
    browserCheck: false,
    externalOpen: false,
    show: false
  },
  {
    icon: [],
    label: 'Plus და MR ქულებით',
    status: false,
    value: PaymentType.PlusPoints,
    externalOpen: false
  },
  {
    icon: [],
    label: 'Visa-ს ციფრული ბარათი',
    status: false,
    value: PaymentType.VisaDigitalCard,
    externalOpen: false
  },
  {
    icon: [],
    label: 'Visa-ს ფასდაკლებით',
    status: false,
    value: PaymentType.VisaDiscount,
    externalOpen: false
  },
  {
    icon: [],
    label: 'ApplePay',
    status: false,
    value: PaymentType.ApplePay,
    externalOpen: false
  },
  {
    icon: [],
    label: 'განაწილება',
    status: false,
    value: PaymentType.PartByPartTBC,
    externalOpen: false
  },
  {
    icon: [],
    label: 'ბარათით',
    status: false,
    value: PaymentType.TBCPay,
    externalOpen: false
  }
];

export const ORDER_FILTER_FOR_CUSTOMER_OPTIONS = [
  {
    color: 'text-primary',
    label: 'ყველა',
    value: null,
    active: true
  },
  {
    color: 'text-primary',
    label: 'მიმდინარე',
    value: OrderFilterForCustomerType.Current,
    active: false
  },
  {
    color: 'text-success',
    label: 'ჩაბარებული',
    value: OrderFilterForCustomerType.Delivered,
    active: false
  },
  {
    color: 'text-pink',
    label: 'გაუქმებული',
    value: OrderFilterForCustomerType.Cancelled,
    active: false
  }
];

export const ORDER_FOR_CUSTOMER_VIEW = [
  {
    color: 'text-primary',
    label: 'მივიღეთ',
    value: OrderForCustomerViewType.Recived,
    active: true
  },
  {
    color: 'text-primary',
    label: 'ველოდებით',
    value: OrderForCustomerViewType.Waiting,
    active: true
  },
  {
    color: 'text-primary',
    label: 'ვამუშავებთ',
    value: OrderForCustomerViewType.Process,
    active: true
  },
  {
    color: 'text-primary',
    label: 'ვაგროვებთ',
    value: OrderForCustomerViewType.Collect,
    active: true
  },
  {
    color: 'text-primary',
    label: 'ვამზადებთ',
    value: OrderForCustomerViewType.Prepare,
    active: true
  },
  {
    color: 'text-primary',
    label: 'მოგვაქვს',
    value: OrderForCustomerViewType.Bring,
    active: true
  },
  {
    color: 'text-primary',
    label: 'ჩაბარებულია',
    value: OrderForCustomerViewType.DeliveredC,
    active: true
  },
  {
    color: 'text-primary',
    label: 'ფოსტაშია',
    value: OrderForCustomerViewType.DeliveredP,
    active: true
  },
  {
    color: 'text-primary',
    label: 'გაუქმებულია',
    value: OrderForCustomerViewType.Cancelled,
    active: true
  },
  {
    color: 'text-primary',
    label: 'გატანის წერტილშია',
    value: OrderForCustomerViewType.DeliveredAtPickup,
    active: true
  }
];
