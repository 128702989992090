<div
  class="_x_fixed _x_left-0 _x_right-0 _x_top-0 _x_z-51 _x_flex _x_h-full _x_max-h-full _x_w-full _x_items-center _x_justify-center _x_overflow-y-auto _x_overflow-x-hidden _x_p-8">
  <div
    class="_x_fixed _x_left-0 _x_right-0 _x_top-0 _x_z-10 _x_h-full _x_w-full _x_bg-dark-600"
    (click)="closeModal()"></div>
  <div class="_x_relative _x_z-10 _x_max-h-full _x_w-full _x_max-w-212">
    <div class="_x_relative _x_rounded-12 _x_bg-white">
      <div class="_x_flex _x_items-center _x_justify-between _x_border-b _x_border-gray _x_p-8">
        <div class="_x_flex _x_items-center">
          <span class="_x_font-bold _x_text-5">{{ confirmationModalData?.title }}</span>
        </div>
        <button type="button" (click)="closeModal()">
          <i
            class="_x_icon _x_icon-remove _x_flex _x_h-12 _x_max-h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_cursor-pointer _x_items-center _x_justify-center _x_text-7 _x_text-black"></i>
        </button>
      </div>
      <div class="ng-star-inserted _x_p-8">
        <p class="_x_mb-12 _x_text-left _x_font-medium _x_text-3 _x_text-dark">{{ confirmationModalData?.text }}</p>
        <div class="_x_flex _x_items-center _x_gap-4 sm:_x_gap-8">
          <button
            class="_x_h-24 _x_w-full _x_items-center _x_justify-center _x_rounded-8 _x_border-1 _x_border-purple _x_font-medium _x_text-3 _x_text-purple _x_transition _x_duration-300 hover:_x_border-2 lg:_x_flex"
            (click)="closeModal()">
            არა
          </button>
          <button
            class="_x_h-24 _x_w-full _x_items-center _x_justify-center _x_rounded-8 _x_bg-purple _x_font-medium _x_text-3 _x_text-white _x_transition _x_duration-300 hover:_x_bg-purple-100 hover:_x_text-purple lg:_x_flex"
            (click)="save()">
            კი
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
