export const FEEDBACK_ICONS: any[] = [
  {
    icon: `<svg width="55" height="54" viewBox="0 0 55 54" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M31.3087 8.17783L35.1789 15.9986C35.5064 16.5861 35.9547 17.0976 36.4942 17.4992C37.0338 17.9009 37.6523 18.1836 38.309 18.3289L45.3094 19.509C49.7996 20.2591 50.8597 23.5094 47.6195 26.7797L42.1692 32.2803C41.6926 32.8197 41.3428 33.4589 41.1455 34.1512C40.9482 34.8434 40.9085 35.5711 41.0292 36.2807L42.5893 43.0914C43.8193 48.4819 40.9792 50.5621 36.2489 47.7419L29.6786 43.8215C29.0051 43.4717 28.2574 43.2891 27.4985 43.2891C26.7396 43.2891 25.9918 43.4717 25.3184 43.8215L18.738 47.7419C14.0378 50.5621 11.1776 48.4519 12.4077 43.0914L13.9678 36.2807C14.0843 35.5703 14.0415 34.843 13.8426 34.1511C13.6437 33.4593 13.2937 32.8203 12.8177 32.2803L7.36744 26.7797C4.15727 23.5494 5.18733 20.2691 9.67756 19.509L16.6779 18.3289C17.3347 18.1836 17.9532 17.9009 18.4927 17.4992C19.0323 17.0976 19.4806 16.5861 19.8081 15.9986L23.6783 8.19783C25.7884 3.9374 29.2186 3.9374 31.3087 8.17783Z" fill="#EDEDED" stroke="#EDEDED" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M22.5 27L23.4174 25.5846C23.4692 25.5139 23.511 25.4248 23.5396 25.3237C23.5683 25.2225 23.5832 25.112 23.5832 25C23.5832 24.888 23.5683 24.7775 23.5396 24.6763C23.511 24.5752 23.4692 24.4861 23.4174 24.4154C23.0229 23.8154 22.656 23.2769 22.5 23M32.5 27L31.5826 25.5846C31.5308 25.5139 31.489 25.4248 31.4604 25.3237C31.4317 25.2225 31.4168 25.112 31.4168 25C31.4168 24.888 31.4317 24.7775 31.4604 24.6763C31.489 24.5752 31.5308 24.4861 31.5826 24.4154C31.9771 23.8154 32.344 23.2769 32.5 23" stroke="#BFBEC1" stroke-width="2" stroke-linecap="round"/>
          <path d="M23.5 35C      24.0279 34.3226 24.6654 33.7946 25.3698 33.4514C26.0741 33.1082 26.829 32.9577 27.5836 33.0102C28.3108 32.9842 29.0345 33.1477 29.7086 33.4902C30.3828 33.8327 30.9928 34.3468 31.5 35" stroke="#BFBEC1" stroke-width="2" stroke-linecap="round"/>
      </svg>`,
    iconActive: `<svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M30.8087 8.17783L34.6789 15.9986C35.0064 16.5861 35.4547 17.0976 35.9942 17.4992C36.5338 17.9009 37.1523 18.1836 37.809 18.3289L44.8094 19.509C49.2996 20.2591 50.3597 23.5094 47.1195 26.7797L41.6692 32.2803C41.1926 32.8197 40.8428 33.4589 40.6455 34.1512C40.4482 34.8434 40.4085 35.5711 40.5292 36.2807L42.0893 43.0914C43.3193 48.4819 40.4792 50.5621 35.7489 47.7419L29.1786 43.8215C28.5051 43.4717 27.7574 43.2891 26.9985 43.2891C26.2396 43.2891 25.4918 43.4717 24.8184 43.8215L18.238 47.7419C13.5378 50.5621 10.6776 48.4519 11.9077 43.0914L13.4678 36.2807C13.5843 35.5703 13.5415 34.843 13.3426 34.1511C13.1437 33.4593 12.7937 32.8203 12.3177 32.2803L6.86744 26.7797C3.65727 23.5494 4.68733 20.2691 9.17756 19.509L16.1779 18.3289C16.8347 18.1836 17.4532 17.9009 17.9927 17.4992C18.5323 17.0976 18.9806 16.5861 19.3081 15.9986L23.1783 8.19783C25.2884 3.9374 28.7186 3.9374 30.8087 8.17783Z" fill="#F9AC55" stroke="#F9AC55" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                     <path d="M22 27L22.9174 25.5846C22.9692 25.5139 23.011 25.4248 23.0396 25.3237C23.0683 25.2225 23.0832 25.112 23.0832 25C23.0832 24.888 23.0683 24.7775 23.0396 24.6763C23.011 24.5752 22.9692 24.4861 22.9174 24.4154C22.5229 23.8154 22.156 23.2769 22 23M32 27L31.0826 25.5846C31.0308 25.5139 30.989 25.4248 30.9604 25.3237C30.9317 25.2225 30.9168 25.112 30.9168 25C30.9168 24.888 30.9317 24.7775 30.9604 24.6763C30.989 24.5752 31.0308 24.4861 31.0826 24.4154C31.4771 23.8154 31.844 23.2769 32 23" stroke="#2B2731" stroke-width="2" stroke-linecap="round"/>
                     <path d="M23 35C23.5279 34.3226 24.1654 33.7946 24.8698 33.4514C25.5741 33.1082 26.329 32.9577 27.0836 33.0102C27.8108 32.9842 28.5345 33.1477 29.2086 33.4902C29.8828 33.8327 30.4928 34.3468 31 35" stroke="#2B2731" stroke-width="2" stroke-linecap="round"/>
                 </svg>              `,
    text: 'ძალიან ცუდი'
  },
  {
    icon: `<svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_20824_3555)">
                <path d="M30.8087 8.17783L34.6789 15.9986C35.0064 16.5861 35.4547 17.0976 35.9942 17.4992C36.5338 17.9009 37.1523 18.1836 37.809 18.3289L44.8094 19.509C49.2996 20.2591 50.3597 23.5094 47.1195 26.7797L41.6692 32.2803C41.1926 32.8197 40.8428 33.4589 40.6455 34.1512C40.4482 34.8434 40.4085 35.5711 40.5292 36.2807L42.0893 43.0914C43.3193 48.4819 40.4792 50.5621 35.7489 47.7419L29.1786 43.8215C28.5051 43.4717 27.7574 43.2891 26.9985 43.2891C26.2396 43.2891 25.4918 43.4717 24.8184 43.8215L18.238 47.7419C13.5378 50.5621 10.6776 48.4519 11.9077 43.0914L13.4678 36.2807C13.5843 35.5703 13.5415 34.843 13.3426 34.1511C13.1437 33.4593 12.7937 32.8203 12.3177 32.2803L6.86744 26.7797C3.65727 23.5494 4.68733 20.2691 9.17756 19.509L16.1779 18.3289C16.8347 18.1836 17.4532 17.9009 17.9927 17.4992C18.5323 17.0976 18.9806 16.5861 19.3081 15.9986L23.1783 8.19783C25.2884 3.9374 28.7186 3.9374 30.8087 8.17783Z" fill="#EDEDED" stroke="#EDEDED" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M23 35C23.5279 34.3226 24.1654 33.7946 24.8698 33.4514C25.5741 33.1082 26.329 32.9577 27.0836 33.0102C27.8108 32.9842 28.5345 33.1477 29.2086 33.4902C29.8828 33.8327 30.4928 34.3468 31 35" stroke="#BFBEC1" stroke-width="2" stroke-linecap="round"/>
                <path d="M23 27C24.1046 27 25 26.1046 25 25C25 23.8954 24.1046 23 23 23C21.8954 23 21 23.8954 21 25C21 26.1046 21.8954 27 23 27Z" fill="#BFBEC1"/>
                <path d="M31 27C32.1046 27 33 26.1046 33 25C33 23.8954 32.1046 23 31 23C29.8954 23 29 23.8954 29 25C29 26.1046 29.8954 27 31 27Z" fill="#BFBEC1"/>
                </g>
                <defs>
                <clipPath id="clip0_20824_3555">
                <rect width="54" height="54" fill="white"/>
                </clipPath>
                </defs>
           </svg>`,
    iconActive: `<svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_20824_3514)">
                      <path d="M30.8087 8.17783L34.6789 15.9986C35.0064 16.5861 35.4547 17.0976 35.9942 17.4992C36.5338 17.9009 37.1523 18.1836 37.809 18.3289L44.8094 19.509C49.2996 20.2591 50.3597 23.5094 47.1195 26.7797L41.6692 32.2803C41.1926 32.8197 40.8428 33.4589 40.6455 34.1512C40.4482 34.8434 40.4085 35.5711 40.5292 36.2807L42.0893 43.0914C43.3193 48.4819 40.4792 50.5621 35.7489 47.7419L29.1786 43.8215C28.5051 43.4717 27.7574 43.2891 26.9985 43.2891C26.2396 43.2891 25.4918 43.4717 24.8184 43.8215L18.238 47.7419C13.5378 50.5621 10.6776 48.4519 11.9077 43.0914L13.4678 36.2807C13.5843 35.5703 13.5415 34.843 13.3426 34.1511C13.1437 33.4593 12.7937 32.8203 12.3177 32.2803L6.86744 26.7797C3.65727 23.5494 4.68733 20.2691 9.17756 19.509L16.1779 18.3289C16.8347 18.1836 17.4532 17.9009 17.9927 17.4992C18.5323 17.0976 18.9806 16.5861 19.3081 15.9986L23.1783 8.19783C25.2884 3.9374 28.7186 3.9374 30.8087 8.17783Z" fill="#F9AC55" stroke="#F9AC55" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M23 35C23.5279 34.3226 24.1654 33.7946 24.8698 33.4514C25.5741 33.1082 26.329 32.9577 27.0836 33.0102C27.8108 32.9842 28.5345 33.1477 29.2086 33.4902C29.8828 33.8327 30.4928 34.3468 31 35" stroke="#2B2731" stroke-width="2" stroke-linecap="round"/>
                      <path d="M23 27C24.1046 27 25 26.1046 25 25C25 23.8954 24.1046 23 23 23C21.8954 23 21 23.8954 21 25C21 26.1046 21.8954 27 23 27Z" fill="#2B2731"/>
                      <path d="M31 27C32.1046 27 33 26.1046 33 25C33 23.8954 32.1046 23 31 23C29.8954 23 29 23.8954 29 25C29 26.1046 29.8954 27 31 27Z" fill="#2B2731"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_20824_3514">
                      <rect width="54" height="54" fill="white"/>
                      </clipPath>
                      </defs>
                 </svg>`,
    text: 'ცუდი'
  },
  {
    icon: `
           <svg width="55" height="54" viewBox="0 0 55 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_20188_5120)">
                <path d="M31.3087 8.17783L35.1789 15.9986C35.5064 16.5861 35.9547 17.0976 36.4942 17.4992C37.0338 17.9009 37.6523 18.1836 38.309 18.3289L45.3094 19.509C49.7996 20.2591 50.8597 23.5094 47.6195 26.7797L42.1692 32.2803C41.6926 32.8197 41.3428 33.4589 41.1455 34.1512C40.9482 34.8434 40.9085 35.5711 41.0292 36.2807L42.5893 43.0914C43.8193 48.4819 40.9792 50.5621 36.2489 47.7419L29.6786 43.8215C29.0051 43.4717 28.2574 43.2891 27.4985 43.2891C26.7396 43.2891 25.9918 43.4717 25.3184 43.8215L18.738 47.7419C14.0378 50.5621 11.1776 48.4519 12.4077 43.0914L13.9678 36.2807C14.0843 35.5703 14.0415 34.843 13.8426 34.1511C13.6437 33.4593 13.2937 32.8203 12.8177 32.2803L7.36744 26.7797C4.15727 23.5494 5.18733 20.2691 9.67756 19.509L16.6779 18.3289C17.3347 18.1836 17.9532 17.9009 18.4927 17.4992C19.0323 17.0976 19.4806 16.5861 19.8081 15.9986L23.6783 8.19783C25.7884 3.9374 29.2186 3.9374 31.3087 8.17783Z" fill="#EDEDED" stroke="#EDEDED" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M23.5 27C24.6046 27 25.5 26.1046 25.5 25C25.5 23.8954 24.6046 23 23.5 23C22.3954 23 21.5 23.8954 21.5 25C21.5 26.1046 22.3954 27 23.5 27Z" fill="#BFBEC1"/>
                <path d="M31.6001 27C32.7047 27 33.6001 26.1046 33.6001 25C33.6001 23.8954 32.7047 23 31.6001 23C30.4955 23 29.6001 23.8954 29.6001 25C29.6001 26.1046 30.4955 27 31.6001 27Z" fill="#BFBEC1"/>
                <path d="M23.5 33H31.5" stroke="#BFBEC1" stroke-width="2" stroke-linecap="round"/></g>
                <defs>
                <clipPath id="clip0_20188_5120">
                <rect width="54" height="54" fill="white" transform="translate(0.5)"/>
                </clipPath>
                </defs>
           </svg>
    `,
    iconActive: `
                <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_20824_3522)">
                  <path d="M30.8087 8.17783L34.6789 15.9986C35.0064 16.5861 35.4547 17.0976 35.9942 17.4992C36.5338 17.9009 37.1523 18.1836 37.809 18.3289L44.8094 19.509C49.2996 20.2591 50.3597 23.5094 47.1195 26.7797L41.6692 32.2803C41.1926 32.8197 40.8428 33.4589 40.6455 34.1512C40.4482 34.8434 40.4085 35.5711 40.5292 36.2807L42.0893 43.0914C43.3193 48.4819 40.4792 50.5621 35.7489 47.7419L29.1786 43.8215C28.5051 43.4717 27.7574 43.2891 26.9985 43.2891C26.2396 43.2891 25.4918 43.4717 24.8184 43.8215L18.238 47.7419C13.5378 50.5621 10.6776 48.4519 11.9077 43.0914L13.4678 36.2807C13.5843 35.5703 13.5415 34.843 13.3426 34.1511C13.1437 33.4593 12.7937 32.8203 12.3177 32.2803L6.86744 26.7797C3.65727 23.5494 4.68733 20.2691 9.17756 19.509L16.1779 18.3289C16.8347 18.1836 17.4532 17.9009 17.9927 17.4992C18.5323 17.0976 18.9806 16.5861 19.3081 15.9986L23.1783 8.19783C25.2884 3.9374 28.7186 3.9374 30.8087 8.17783Z" fill="#F9AC55" stroke="#F9AC55" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M23 27C24.1046 27 25 26.1046 25 25C25 23.8954 24.1046 23 23 23C21.8954 23 21 23.8954 21 25C21 26.1046 21.8954 27 23 27Z" fill="#2B2731"/>
                  <path d="M31.1001 27C32.2047 27 33.1001 26.1046 33.1001 25C33.1001 23.8954 32.2047 23 31.1001 23C29.9955 23 29.1001 23.8954 29.1001 25C29.1001 26.1046 29.9955 27 31.1001 27Z" fill="#2B2731"/>
                  <path d="M23 33H31" stroke="#2B2731" stroke-width="2" stroke-linecap="round"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_20824_3522">
                  <rect width="54" height="54" fill="white"/>
                  </clipPath>
                  </defs>
                </svg>`,
    text: 'ნეიტრალური'
  },
  {
    icon: `
          <svg width="55" height="54" viewBox="0 0 55 54" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M31.3087 8.17783L35.1789 15.9986C35.5064 16.5861 35.9547 17.0976 36.4942 17.4992C37.0338 17.9009 37.6523 18.1836 38.309 18.3289L45.3094 19.509C49.7996 20.2591 50.8597 23.5094 47.6195 26.7797L42.1692 32.2803C41.6926 32.8197 41.3428 33.4589 41.1455 34.1512C40.9482 34.8434 40.9085 35.5711 41.0292 36.2807L42.5893 43.0914C43.8193 48.4819 40.9792 50.5621 36.2489 47.7419L29.6786 43.8215C29.0051 43.4717 28.2574 43.2891 27.4985 43.2891C26.7396 43.2891 25.9918 43.4717 25.3184 43.8215L18.738 47.7419C14.0378 50.5621 11.1776 48.4519 12.4077 43.0914L13.9678 36.2807C14.0843 35.5703 14.0415 34.843 13.8426 34.1511C13.6437 33.4593 13.2937 32.8203 12.8177 32.2803L7.36744 26.7797C4.15727 23.5494 5.18733 20.2691 9.67756 19.509L16.6779 18.3289C17.3347 18.1836 17.9532 17.9009 18.4927 17.4992C19.0323 17.0976 19.4806 16.5861 19.8081 15.9986L23.6783 8.19783C25.7884 3.9374 29.2186 3.9374 31.3087 8.17783Z" fill="#EDEDED" stroke="#EDEDED" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M23.5498 27C24.6544 27 25.5498 26.1046 25.5498 25C25.5498 23.8954 24.6544 23 23.5498 23C22.4452 23 21.5498 23.8954 21.5498 25C21.5498 26.1046 22.4452 27 23.5498 27Z" fill="#BFBEC1"/>
              <path d="M31.6499 27C32.7545 27 33.6499 26.1046 33.6499 25C33.6499 23.8954 32.7545 23 31.6499 23C30.5453 23 29.6499 23.8954 29.6499 25C29.6499 26.1046 30.5453 27 31.6499 27Z" fill="#BFBEC1"/>
              <path d="M24.3501 30.9082C24.3501 31.7436 24.682 32.5449 25.2727 33.1356C25.8635 33.7263 26.6647 34.0582 27.5001 34.0582C28.3355 34.0582 29.1367 33.7263 29.7275 33.1356C30.3182 32.5449 30.6501 31.7436 30.6501 30.9082" stroke="#BFBEC1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>

    `,
    iconActive: `<svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_20824_3530)">
                      <path d="M30.8087 8.17783L34.6789 15.9986C35.0064 16.5861 35.4547 17.0976 35.9942 17.4992C36.5338 17.9009 37.1523 18.1836 37.809 18.3289L44.8094 19.509C49.2996 20.2591 50.3597 23.5094 47.1195 26.7797L41.6692 32.2803C41.1926 32.8197 40.8428 33.4589 40.6455 34.1512C40.4482 34.8434 40.4085 35.5711 40.5292 36.2807L42.0893 43.0914C43.3193 48.4819 40.4792 50.5621 35.7489 47.7419L29.1786 43.8215C28.5051 43.4717 27.7574 43.2891 26.9985 43.2891C26.2396 43.2891 25.4918 43.4717 24.8184 43.8215L18.238 47.7419C13.5378 50.5621 10.6776 48.4519 11.9077 43.0914L13.4678 36.2807C13.5843 35.5703 13.5415 34.843 13.3426 34.1511C13.1437 33.4593 12.7937 32.8203 12.3177 32.2803L6.86744 26.7797C3.65727 23.5494 4.68733 20.2691 9.17756 19.509L16.1779 18.3289C16.8347 18.1836 17.4532 17.9009 17.9927 17.4992C18.5323 17.0976 18.9806 16.5861 19.3081 15.9986L23.1783 8.19783C25.2884 3.9374 28.7186 3.9374 30.8087 8.17783Z" fill="#F9AC55" stroke="#F9AC55" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M23.0498 27C24.1544 27 25.0498 26.1046 25.0498 25C25.0498 23.8954 24.1544 23 23.0498 23C21.9452 23 21.0498 23.8954 21.0498 25C21.0498 26.1046 21.9452 27 23.0498 27Z" fill="#2B2731"/>
                      <path d="M31.1499 27C32.2545 27 33.1499 26.1046 33.1499 25C33.1499 23.8954 32.2545 23 31.1499 23C30.0453 23 29.1499 23.8954 29.1499 25C29.1499 26.1046 30.0453 27 31.1499 27Z" fill="#2B2731"/>
                      <path d="M23.8501 30.908C23.8501 31.7434 24.182 32.5446 24.7727 33.1353C25.3635 33.7261 26.1647 34.058 27.0001 34.058C27.8355 34.058 28.6367 33.7261 29.2275 33.1353C29.8182 32.5446 30.1501 31.7434 30.1501 30.908" stroke="#2B2731" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_20824_3530">
                      <rect width="54" height="54" fill="white"/>
                      </clipPath>
                      </defs>
                 </svg>`,
    text: 'კარგი'
  },
  {
    icon: `
           <svg width="55" height="54" viewBox="0 0 55 54" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M31.3087 8.17783L35.1789 15.9986C35.5064 16.5861 35.9547 17.0976 36.4942 17.4992C37.0338 17.9009 37.6523 18.1836 38.309 18.3289L45.3094 19.509C49.7996 20.2591 50.8597 23.5094 47.6195 26.7797L42.1692 32.2803C41.6926 32.8197 41.3428 33.4589 41.1455 34.1512C40.9482 34.8434 40.9085 35.5711 41.0292 36.2807L42.5893 43.0914C43.8193 48.4819 40.9792 50.5621 36.2489 47.7419L29.6786 43.8215C29.0051 43.4717 28.2574 43.2891 27.4985 43.2891C26.7396 43.2891 25.9918 43.4717 25.3184 43.8215L18.738 47.7419C14.0378 50.5621 11.1776 48.4519 12.4077 43.0914L13.9678 36.2807C14.0843 35.5703 14.0415 34.843 13.8426 34.1511C13.6437 33.4593 13.2937 32.8203 12.8177 32.2803L7.36744 26.7797C4.15727 23.5494 5.18733 20.2691 9.67756 19.509L16.6779 18.3289C17.3347 18.1836 17.9532 17.9009 18.4927 17.4992C19.0323 17.0976 19.4806 16.5861 19.8081 15.9986L23.6783 8.19783C25.7884 3.9374 29.2186 3.9374 31.3087 8.17783Z" fill="#EDEDED" stroke="#EDEDED" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M33.5 25L32.0772 23.3012C31.9238 23.1083 31.7163 23 31.5 23C31.2837 23 31.0762 23.1083 30.9228 23.3012C30.3188 24.0317 29.7819 24.6942 29.5 25" stroke="#BFBEC1" stroke-width="2" stroke-linecap="round"/>
              <path d="M25.5 25L24.0772 23.3012C23.9238 23.1083 23.7163 23 23.5 23C23.2837 23 23.0762 23.1083 22.9228 23.3012C22.3188 24.0317 21.7819 24.6942 21.5 25" stroke="#BFBEC1" stroke-width="2" stroke-linecap="round"/>
              <path d="M21.5 30C21.5 31.5913 22.1321 33.1174 23.2574 34.2426C24.3826 35.3679 25.9087 36 27.5 36C29.0913 36 30.6174 35.3679 31.7426 34.2426C32.8679 33.1174 33.5 31.5913 33.5 30H21.5Z" fill="#EDEDED" stroke="#BFBEC1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
           </svg>

    `,
    iconActive: `<svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_20824_3538)">
                      <path d="M30.8087 8.17783L34.6789 15.9986C35.0064 16.5861 35.4547 17.0976 35.9942 17.4992C36.5338 17.9009 37.1523 18.1836 37.809 18.3289L44.8094 19.509C49.2996 20.2591 50.3597 23.5094 47.1195 26.7797L41.6692 32.2803C41.1926 32.8197 40.8428 33.4589 40.6455 34.1512C40.4482 34.8434 40.4085 35.5711 40.5292 36.2807L42.0893 43.0914C43.3193 48.4819 40.4792 50.5621 35.7489 47.7419L29.1786 43.8215C28.5051 43.4717 27.7574 43.2891 26.9985 43.2891C26.2396 43.2891 25.4918 43.4717 24.8184 43.8215L18.238 47.7419C13.5378 50.5621 10.6776 48.4519 11.9077 43.0914L13.4678 36.2807C13.5843 35.5703 13.5415 34.843 13.3426 34.1511C13.1437 33.4593 12.7937 32.8203 12.3177 32.2803L6.86744 26.7797C3.65727 23.5494 4.68733 20.2691 9.17756 19.509L16.1779 18.3289C16.8347 18.1836 17.4532 17.9009 17.9927 17.4992C18.5323 17.0976 18.9806 16.5861 19.3081 15.9986L23.1783 8.19783C25.2884 3.9374 28.7186 3.9374 30.8087 8.17783Z" fill="#F9AC55" stroke="#F9A140" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M33 25L31.5772 23.3012C31.4238 23.1083 31.2163 23 31 23C30.7837 23 30.5762 23.1083 30.4228 23.3012C29.8188 24.0317 29.2819 24.6942 29 25" stroke="#2B2731" stroke-width="2" stroke-linecap="round"/>
                      <path d="M25 25L23.5772 23.3012C23.4238 23.1083 23.2163 23 23 23C22.7837 23 22.5762 23.1083 22.4228 23.3012C21.8188 24.0317 21.2819 24.6942 21 25" stroke="#2B2731" stroke-width="2" stroke-linecap="round"/>
                      <path d="M21 30C21 31.5913 21.6321 33.1174 22.7574 34.2426C23.8826 35.3679 25.4087 36 27 36C28.5913 36 30.1174 35.3679 31.2426 34.2426C32.3679 33.1174 33 31.5913 33 30H21Z" fill="#F45757" stroke="#2B2731" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_20824_3538">
                      <rect width="54" height="54" fill="white"/>
                      </clipPath>
                      </defs>
                 </svg>`,
    text: 'ძალიან კარგი'
  }
];
