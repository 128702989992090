import {} from '@angular/common';
import { OrderingHttp } from 'lib-core';
import { BehaviorSubject, filter, finalize, map, Subject, takeUntil } from 'rxjs';

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Order } from 'lib-core/lib/models/orders.model';
import { MY_ORDERS_PAYMENT_METHODS, ORDERLINE_STATUS_NAMING } from 'projects/web/src/app/constants/my-orders.constants';
import { OrderDebtService } from 'projects/web/src/app/services/order-debt.service';
import { NumberToArrayPipe } from 'projects/web/src/app/shared/pipe/number-to-array.pipe';
import { PaymentLabelPipe } from 'projects/web/src/app/shared/pipe/payment-label-pipe';
import { PipesModule } from 'projects/web/src/app/shared/pipe/pipes.module';
import {
  ExpressOrderFinancialStatus,
  ExpressOrderFinancialType,
  OrderDeliveryType,
  OrderLineForCustomerViewType,
  PaymentType
} from 'projects/web/src/app/store/models/orders.model';
import { VoucherType } from '../../../../../../lib-core/src/lib/models/vouchers.model';
import { UserFeedbackService } from '../user-feedback.service';

@Component({
  selector: 'app-user-feedback-order-details',
  standalone: true,
  imports: [CommonModule, NumberToArrayPipe, PaymentLabelPipe, PipesModule, RouterLink],
  providers: [OrderDebtService, OrderingHttp],
  templateUrl: './user-feedback-order-details.component.html',
  styleUrls: ['./user-feedback-order-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserFeedbackOrderDetailsComponent implements OnInit, OnDestroy {
  public orderDetailsLoadingState$ = new BehaviorSubject<boolean>(false);
  public orderDetails$ = new BehaviorSubject<Order>(null);

  // public orderDetails$: Observable<Order> = this.myOrdersDetailedFacadeService.orderDetails$;

  // public orderDetailsLoadingState$: Observable<boolean> = this.myOrdersDetailedFacadeService.orderDetailsLoadingState$;

  public readonly voucherType = VoucherType;

  public readonly expressOrderStatus = ExpressOrderFinancialStatus;

  public readonly expressOrderType = ExpressOrderFinancialType;

  public readonly orderLineForCustomerViewTypeEnum = OrderLineForCustomerViewType;

  public readonly orderLineStatusNaming = ORDERLINE_STATUS_NAMING;

  public readonly paymentMethod = MY_ORDERS_PAYMENT_METHODS;

  public readonly orderDeliveryType = OrderDeliveryType;

  public readonly paymentType = PaymentType;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private orderDebtService: OrderDebtService,
    private orderingHttp: OrderingHttp,
    private userFeedbackService: UserFeedbackService
  ) {}

  ngOnInit() {
    this.orderingHttp
      .fetchCustomerSingleOrder(this.userFeedbackService.getOrderId())
      .pipe(
        finalize(() => this.orderDetailsLoadingState$.next(false)),
        filter(order => order),
        map((order: Order) => {
          order.orderLines[0].open = true;
          order.orderLines.forEach(res => {
            res.lineHasPriceDifference = res.lines.some(line => !!line.priceDifference);
          });
          return order;
        })
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((order: Order) => {
        this.orderDetails$.next(order);
      });
  }

  public payDept(order: Order): void {
    this.orderDebtService.openPaymentModal(order);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
