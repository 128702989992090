import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../api.service';
import { Guid } from 'guid-typescript';

@Injectable({
  providedIn: 'root'
})
export class BasketHttp {
  constructor(private api: ApiService) {}

  getReport(data): Observable<any> {
    return this.api.get('v1/reports/abandoned-carts', data, 'basketUrl', 'blob');
  }

  getUserBasket(darkStoreId: number = null): Observable<any> {
    return this.api.get(`v1/basket`, { darkStoreId }, 'basketUrl');
  }

  getUserAdminBasket(userId): Observable<any> {
    return this.api.get(`v1/adminbasket`, { userId: userId }, 'basketUrl');
  }

  getUserCheckOutBasket(data): Observable<any> {
    return this.api.post(`api/orders/checkout-prices`, data, { requestId: this.getGuidId() }, 'orderingURL');
  }

  createorderbyadministrator(data, userId): Observable<any> {
    return this.api.post(
      `api/orders/createorderbyadministrator`,
      data,
      { requestId: this.getGuidId(), userId: userId },
      'orderingURL'
    );
  }

  updateUserBasket(basket, darkStoreId?: number): Observable<any> {
    let data = {
      basket,
      darkStoreId
    };
    return this.api.post('v1/basket/updatebasket', data, { requestId: this.getGuidId() }, 'basketUrl');
  }

  updateBasketExpressProducts(darkStoreId: number): Observable<any> {
    return this.api.put(
      'v1/basket/updatebasketexpressproducts',
      { darkStoreId },
      { requestId: this.getGuidId() },
      'basketUrl'
    );
  }

  updateUserBasketMultiple(data): Observable<any> {
    return this.api.post('v1/basket/updatebasketmultiple', data, { requestId: this.getGuidId() }, 'basketUrl');
  }

  updateUserBasketMultiplev2(data): Observable<any> {
    return this.api.post('v1/basket/updatebasketproducts', data, { requestId: this.getGuidId() }, 'basketUrl');
  }

  getAnonymousUserBasket(data, darkStoreId?: number): Observable<any> {
    if (data == null) {
      data = {};
    }
    data.darkStoreId = darkStoreId;
    return this.api.post(
      'v1/basket/for-anonymous-user',
      data,
      { darkStoreId, requestId: this.getGuidId() },
      'basketUrl'
    );
  }

  updateUserAdminBasket(data): Observable<any> {
    return this.api.post(
      'v1/adminbasket/updatebasket',
      data,
      { userId: data.userId, requestId: this.getGuidId() },
      'basketUrl'
    );
  }

  deleteBasketProduct(data): Observable<any> {
    return this.api.post('v1/basket/removeProduct', data, { requestId: this.getGuidId() }, 'basketUrl');
  }

  deleteAdminBasketProduct(data): Observable<any> {
    return this.api.post(
      'v1/adminbasket/removeProduct',
      data,
      { userId: data.userId, requestId: this.getGuidId() },
      'basketUrl'
    );
  }

  clearBasketProduct(darkStoreId?: number): Observable<any> {
    return this.api.post('v1/basket/emptybasket', { darkStoreId }, { requestId: this.getGuidId() }, 'basketUrl');
  }

  clearAdminBasketProduct(userId): Observable<any> {
    return this.api.post('v1/adminbasket/emptybasket', {}, { userId: userId }, 'basketUrl');
  }

  getCampaignInformation(params): Observable<any> {
    return this.api.post(
      'api/campaign/getcampaigninformation',
      { ...params },
      { requestId: this.getGuidId() },
      'orderingURL'
    );
  }

  private getGuidId(): string {
    return Guid.create().toString();
  }

  addProductInWishlist(productId: number | string): Observable<any> {
    return this.api.post('v1/wishlist/add-product', { productId }, { requestId: this.getGuidId() }, 'basketUrl');
  }

  getWishlistProducts(data = { page: 1, pageSize: 4 }, darkStoreId: number = null): Observable<any> {
    return this.api.get('v1/wishlist/get-products', { ...data, darkStoreId, requestId: this.getGuidId() }, 'basketUrl');
  }

  getWishlistProductIds(): Observable<any> {
    return this.api.get('v1/wishlist/product-ids', { requestId: this.getGuidId() }, 'basketUrl');
  }

  removeProductFromWishlist(productId): Observable<any> {
    return this.api.delete(`v1/wishlist/remove-product/${productId}`, {}, 'basketUrl');
  }

  updateWishlistExpressProducts(darkStoreId: number): Observable<any> {
    return this.api.put(
      'v1/wishlist/update-express-products',
      { darkStoreId },
      { requestId: this.getGuidId() },
      'basketUrl'
    );
  }
}
