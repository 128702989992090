import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Unsubscriber } from 'lib-core';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import { GlobalModalService } from '../../services/global-modal-service';
import { UserTypeService } from '../../services/user-type.service';
import { getUser, getUserSuccess } from '../actions/user.actions';
import { UserModel } from '../models/user.model';

@Injectable()
export class UserEffects extends Unsubscriber {
  customerService;
  constructor(
    private actions$: Actions,
    private globalModalService: GlobalModalService,
    private userTypeService: UserTypeService
  ) {
    super();
    this.userTypeService
      .getCustomerServiceObservable()
      .pipe(
        takeUntil(this.destroy$),
        filter(s => !!s)
      )
      .subscribe(service => (this.customerService = service));
  }

  getUser = createEffect(() =>
    this.actions$.pipe(
      ofType(getUser),
      mergeMap(() => this.getCustomer())
    )
  );

  getCustomer(): Observable<any> {
    return this.customerService.getCustomer().pipe(
      map((data: UserModel) => {
        // verification popup is disabled for a while
        // if (!this.userTypeService.isUserLegalEntity && data?.shouldBeVerified) {
        //   this.globalModalService.open(NeedVerificationComponent);
        // }
        return getUserSuccess(data);
      })
    );
  }
}
