import { isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Output,
  PLATFORM_ID
} from '@angular/core';
import { Router } from '@angular/router';
import { OverflowHandlerService } from '../../../services/overflow-handler.service';
import {ClickOutsideDirectiveModule} from "../../../shared/directives/clickOutside.directive";

@Component({
  selector: 'app-success-popup',
  templateUrl: './success-popup.component.html',
  standalone: true,
  styleUrls: ['./success-popup.component.scss'],
  imports: [ClickOutsideDirectiveModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuccessPopupComponent {
  @Output() closeSuccessPopup = new EventEmitter<any>();
  load: boolean;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private overflowHandlerService: OverflowHandlerService
  ) {}

  overflowHandler(status: boolean): void {
    if (isPlatformBrowser(this.platformId)) {
      if (status) {
        this.load = true;
        this.overflowHandlerService.hide();
      } else {
        this.load = false;
        this.overflowHandlerService.show();
      }
      this.cdr.markForCheck();
    }
  }

  navigate(url) {
    this.router.navigate([url]);
  }
}
