import { Directive, Output, Input, EventEmitter, ElementRef, HostListener, NgModule } from '@angular/core';

@Directive({
  selector: '[clickOutside]',
  standalone: true
})
export class ClickOutsideDirective {
  @Output() onClickOutside = new EventEmitter<void>();
  @Input() isEnabled?: boolean = true;

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  public onClick(target: HTMLElement) {
    if (!this.isEnabled) return;
    const clickedInside = this.elementRef.nativeElement.contains(target);

    if (!clickedInside && target.id !== 'forOpenContent') {
      this.onClickOutside.emit();
    }
  }
}

@NgModule({
  declarations: [],
  imports: [ClickOutsideDirective],
  exports: [ClickOutsideDirective]
})
export class ClickOutsideDirectiveModule {}
