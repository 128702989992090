import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberToArray',
  standalone: true
})
export class NumberToArrayPipe implements PipeTransform {
  transform(count: number): number[] {
    const array = [];
    for (let i = 0; i < count; i++) {
      array.push(i);
    }

    return array;
  }
}
