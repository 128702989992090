import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FilterPipe } from './filter.pipe';
import { FormatNumberPipe } from './format-number.pipe';
import { FormatBooleanPipe } from './fotmat-boolean.pipe';
import { IsOutOfStockPipe } from './isOutOfStock.pipe';
import { VoucherNamePipe } from './voucherName.pipe';

@NgModule({
  declarations: [FormatNumberPipe, FilterPipe, FormatBooleanPipe, IsOutOfStockPipe, VoucherNamePipe],
  imports: [CommonModule],
  exports: [FormatNumberPipe, FilterPipe, FormatBooleanPipe, IsOutOfStockPipe, VoucherNamePipe],
  providers: []
})
export class PipesModule {}
