import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paymentLabel',
  standalone: true
})
export class PaymentLabelPipe implements PipeTransform {
  transform(item: any, paymentMethod: any[], banksOption: any[]): string {
    const paymentM = paymentMethod.find(pm => pm.value === item.paymentType);

    return paymentM?.label || '';
  }
}
