import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { PaymentHttp } from 'lib-core';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import {
  deleteCard,
  deleteCardSuccess,
  getCards,
  getCardsSuccess,
  setCardtoMain,
  setCardtoMainSuccess
} from '../actions/cards.actions';
import { cardsFeatureSelector } from '../reducers/cards.reducer';

@Injectable()
export class CardsEffects {
  constructor(
    private actions$: Actions,
    private paymentHttp: PaymentHttp,
    private store: Store
  ) {}

  getCards = createEffect(() => {
    return this.actions$.pipe(
      ofType(getCards),
      switchMap(action => {
        return this.paymentHttp.getUserCards(action?.bankType).pipe(
          map(data => {
            return getCardsSuccess(data);
          }),
          catchError(err => of(err))
        );
      })
    );
  });

  deleteCard = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteCard),
      switchMap(action => {
        return this.paymentHttp.deleteCard(action?.id).pipe(
          map(() => deleteCardSuccess({ id: action?.id })),
          catchError(err => of(err))
        );
      })
    );
  });

  setCardtoMain = createEffect(() => {
    return this.actions$.pipe(
      ofType(setCardtoMain),
      switchMap(action => {
        return this.paymentHttp.setMainCard({ cardId: action?.id, accountId: action.accountId }).pipe(
          map(() => setCardtoMainSuccess({ id: action?.id })),
          catchError(err => of(err))
        );
      })
    );
  });

  listenDeleteCardSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteCardSuccess),
      withLatestFrom(this.store.select(cardsFeatureSelector)),
      map(([action, store]) => {
        let lastCard = store[store.length - 1];
        return setCardtoMainSuccess({ id: lastCard?.id });
      })
    )
  );
}
